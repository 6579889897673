import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getTranslatedValue } from '@didomi/helpers';
import { DidomiErrorState } from '@didomi/ui-atoms-react';
import { useSnackbar } from '@didomi/utility-react';
import { PurposeForm } from '@components';
import {
  useCreatePurposeRegulationsOverride,
  useDeletePurposeRegulationsOverride,
  useEditPurposeRegulationsOverride,
  usePurpose,
  usePurposesRegulationsOverrides,
  useRegulations,
} from '@hooks';
import { LoadingModal } from '@modals';
import { checkIfSubmittedPurposesRegulationsAreSameAsOriginal } from '@utils';

/**
 * Edit Global Purpose Page
 */
export const EditGlobalPurpose = () => {
  const { displaySnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { purposeId } = useParams();

  const { data: purpose, isLoading: loadingPurpose, error: errorLoadingPurpose } = usePurpose(purposeId);
  const { data: regulations, isLoading: isLoadingRegulations } = useRegulations();
  const { data: purposesOverrides, isLoading: isLoadingPurposesOverrides } = usePurposesRegulationsOverrides();

  const existingOverride = useMemo(() => {
    return purposesOverrides?.find(o => o.metadata_purpose_id === purposeId);
  }, [purposesOverrides, purposeId]);

  const formInitialValues = useMemo(() => {
    if (!purpose) {
      return {};
    }

    // If there is an existing override, use the regulations_id from the override, otherwise use the regulations_id from the purpose
    const regulations_id = existingOverride ? existingOverride.regulations_id : purpose.regulations_id;
    return { ...purpose, regulations_id };
  }, [purpose, existingOverride]);

  const { mutateAsync: editPurposeRegulationOverride, isLoading: isEditingOverride } = useEditPurposeRegulationsOverride();
  const { mutateAsync: createPurposeRegulationOverride, isLoading: isCreatingOverride } = useCreatePurposeRegulationsOverride();
  const { mutateAsync: deletePurposeRegulationOverride, isLoading: isDeletingOverride } = useDeletePurposeRegulationsOverride();

  const updatePurposeOverrideData = async data => {
    const arePurposeOriginalRegulationsSameAsSubmitted = checkIfSubmittedPurposesRegulationsAreSameAsOriginal(purpose.regulations_id, data.regulations_id);

    if (arePurposeOriginalRegulationsSameAsSubmitted) {
      // if override exist - delete it, if not - do nothing, no changes to save
      return existingOverride && deletePurposeRegulationOverride(existingOverride.id);
    } else {
      return existingOverride
        ? editPurposeRegulationOverride({ ...existingOverride, regulations_id: data.regulations_id })
        : createPurposeRegulationOverride({ regulations_id: data.regulations_id, metadata_purpose_id: purposeId });
    }
  };

  const save = async data => {
    try {
      await updatePurposeOverrideData(data);
      displaySnackbar(`Purpose "${getTranslatedValue(purpose.description)}" has been edited successfully!`, { icon: 'check' });
      navigate('/purposes');
    } catch (error) {
      displaySnackbar(error?.response?.data?.message || 'There was an error saving the changes', { icon: 'danger-light', variant: 'error', duration: 5000 });
    }
  };

  if (!loadingPurpose && !!errorLoadingPurpose) {
    if (errorLoadingPurpose.response.statusText === 'Not Found') {
      return (
        <DidomiErrorState illustrationName="no-matches-found" className="h-full">
          <div slot="title">The purpose does not exist in our records</div>
        </DidomiErrorState>
      );
    }
  }

  return (
    <main className="w-full pb-10">
      <PurposeForm
        initialValues={formInitialValues}
        regulations={regulations}
        onSubmit={save}
        onCancel={() => navigate('/purposes')}
        isLoading={loadingPurpose || isLoadingRegulations || isLoadingPurposesOverrides}
        viewMode="regulation-scoping"
      />
      <LoadingModal data-cy="edit-purpose-saving" isOpen={isEditingOverride || isCreatingOverride || isDeletingOverride} title="We are updating the purpose..." />
    </main>
  );
};
