import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ExistingPurposeRegulationOverride } from '@types';
import { useFetchAllPaginatedResults } from '../../useFetchAllPaginatedResults.hook';

export const usePurposesRegulationsOverrides = (options?: UseQueryOptions<ExistingPurposeRegulationOverride[]>) => {
  const { fetchAllPaginatedResults } = useFetchAllPaginatedResults();
  const { organizationId } = useActiveOrganization();

  const fetchOverrides = async () => fetchAllPaginatedResults<ExistingPurposeRegulationOverride>(`metadata/purposes-regulations-overrides?organization_id=${organizationId}`);

  return useQuery<ExistingPurposeRegulationOverride[], AxiosError>(['purposes-regulations-overrides', organizationId], fetchOverrides, options);
};
