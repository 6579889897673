import { Purpose } from '@types';
import { isTcfIabV1Purpose } from './isTcfIabV1Purpose';

// Purpose is available for selection if it is
// - not a TCF IAB v1 purpose
// OR
// - if it is a TCF IAB v1 purpose and it is selected(so user can unselect it)
export const isPurposeAvailableForSelection = (purpose: Purpose, selectedPurposesIds: string[]) => {
  return !isTcfIabV1Purpose(purpose) || selectedPurposesIds.includes(purpose.id);
};
