import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from '@didomi/utility-react';
import { VendorForm } from '@components';
import { useEditVendorPurposesOverrides, useVendor, useVendorOverridesRequiredPurposes, useVendorStorages, useVendorsPurposesOverrides, useVendorsWithOverrides } from '@hooks';
import { LoadingModal } from '@modals';
import { VendorRequestBody } from '@types';
import { vendorToFormData } from '@utils';

/**
 * Edit Global Vendor Page
 */
export const EditGlobalVendor = (): JSX.Element => {
  const { displaySnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { vendorId } = useParams();

  const { data: purposesOverrides } = useVendorsPurposesOverrides();
  const { data: vendorStorages } = useVendorStorages(vendorId);
  const { data: vendor, isLoading: loadingVendor } = useVendor(vendorId);
  // useVendorsWithOverrides accepts an array of vendor ids, so we pass an array with a single vendor id - later we access the first element of the processedVendors array
  const { processedVendors, isLoading: loadingVendorOverrides } = useVendorsWithOverrides(vendor && [vendor]);
  const { requiredDefaultPurposes, requiredLiPurposes } = useVendorOverridesRequiredPurposes(vendorId);

  const { mutateAsync: updateDefaultPurposesOverrides, isLoading: isSavingDefaultOverrides } = useEditVendorPurposesOverrides({
    vendorId,
    purposeVariantPath: 'partners-default-purposes',
    existingOverrides: purposesOverrides.purposeOverrides,
    originalPurposesIds: vendor?.default_purposes_id,
  });
  const { mutateAsync: updateLiPurposesOverrides, isLoading: isSavingLiOverrides } = useEditVendorPurposesOverrides({
    vendorId,
    purposeVariantPath: 'partners-legitimate-interest-purposes',
    existingOverrides: purposesOverrides.legIntPurposeOverrides,
    originalPurposesIds: vendor?.legitimate_interest_purposes_id,
  });

  const mappedVendor = useMemo(() => vendorToFormData(processedVendors[0], vendorStorages?.data), [processedVendors, vendorStorages]);

  const handleVendorEdition = async (formData: VendorRequestBody) => {
    try {
      await updateDefaultPurposesOverrides(formData.default_purposes_id);
      await updateLiPurposesOverrides(formData.legitimate_interest_purposes_id);

      displaySnackbar(`Vendor has been updated successfully!`, { icon: 'check' });
      navigate('/vendors');
    } catch (error) {
      displaySnackbar(error.response?.data?.message || 'There was an error editing the vendor', { variant: 'error', duration: 5000 });
    }
  };

  return (
    <main className="w-full pb-10">
      <VendorForm
        initialValues={mappedVendor}
        requiredDefaultPurposes={requiredDefaultPurposes}
        requiredLiPurposes={requiredLiPurposes}
        onSubmit={handleVendorEdition}
        onCancel={() => navigate('/vendors')}
        isLoading={loadingVendor || loadingVendorOverrides}
        viewMode="purpose-override"
        atLeastOnePurposeRequired={true}
      />
      <LoadingModal
        data-cy="edit-vendor-saving"
        isOpen={loadingVendor || isSavingDefaultOverrides || isSavingLiOverrides}
        title={loadingVendor ? 'Loading data...' : 'We are updating your vendor...'}
      />
    </main>
  );
};
