import { useMemo } from 'react';
import { extendVendorWithUiData, isActiveVendor } from '@utils';
import { useVendors } from './useVendors.hook';
import { useVendorsWithOverrides } from './useVendorsWithOverrides.hook';
import { useAllPurposes } from '../purposes/useAllPurposes.hook';

/**
 * Custom hook to fetch and process vendors list with UI data.
 *
 * This hook performs the following steps:
 * 1. Fetches vendors and purposes data.
 * 2. Filters out deprecated vendors.
 * 3. Processes vendors to apply purposes overrides.
 * 4. Extends vendors with additional UI data.
 */
export const useVendorsListUiData = () => {
  // 1.
  const { data: purposes, isLoading: loadingPurposes } = useAllPurposes();
  const { isLoading, isRefetching, data: { data: vendors = [] } = {}, error } = useVendors();
  // 2.
  const activeVendors = useMemo(() => vendors?.filter(isActiveVendor), [vendors]);
  // 3.
  const { processedVendors, isLoading: isLoadingProcessedVendors } = useVendorsWithOverrides(activeVendors);
  // 4.
  const extendedUiVendors = useMemo(() => processedVendors.map(v => extendVendorWithUiData(v, purposes.data)), [processedVendors, purposes?.data]);

  return {
    isLoading: loadingPurposes || isLoading || isLoadingProcessedVendors,
    isRefetching,
    error,
    totalVendors: extendedUiVendors.length,
    data: extendedUiVendors,
  };
};
