import { Purpose } from '@types';
import { isSpiPurpose } from './isSpiPurpose';

/*
 * Extract SPI purposes from the list of purposes and filter out non-available regulations
 */
export const getValidSpiPurposes = (purposes: Purpose[] = [], availableRegulationsIds: string[] = []): Purpose[] => {
  const spiPurposes = purposes.filter(isSpiPurpose) || [];
  const getFilteredRegulationsIds = (regulationsId: string[]) => regulationsId?.filter(regId => availableRegulationsIds.includes(regId));
  const hasRegulations = (p: Purpose) => p.regulations_id?.length > 0;

  return spiPurposes.map(p => ({ ...p, regulations_id: getFilteredRegulationsIds(p.regulations_id) })).filter(hasRegulations);
};
