import { haveArraysEqualContent } from './haveArraysEqualContent';

// Check if purposes that are submitted via the form are the same as the original ones
export const checkIfSubmittedPurposesRegulationsAreSameAsOriginal = (originalRegulationIds: string[] | null, submittedRegulationIds: string[] | null) => {
  if (Array.isArray(originalRegulationIds) && Array.isArray(submittedRegulationIds)) {
    return haveArraysEqualContent(originalRegulationIds, submittedRegulationIds);
  } else {
    return originalRegulationIds === submittedRegulationIds;
  }
};
