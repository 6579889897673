import { ExistingVendorPurposeOverride } from '@types';
import { haveArraysEqualContent } from './haveArraysEqualContent';

// Compare existing vendors purposes overrides with the new selection
// Return the diff that contain overrides that need to be added and removed
export const getPurposesOverridesDiffForVendor = (
  vendorId: string,
  allOverrides: ExistingVendorPurposeOverride[],
  selectedPurposesIds: string[],
  originalPurposesIds: string[],
) => {
  const previousOverridesForVendor = allOverrides.filter(override => override.metadata_partner_id === vendorId);
  const isNewSelectionSameAsOriginal = haveArraysEqualContent(selectedPurposesIds, originalPurposesIds);

  // If the new selection is the same as the original - remove all overrides
  if (isNewSelectionSameAsOriginal) {
    return { toAdd: [], toDelete: previousOverridesForVendor.map(o => o.id) };
  }

  // If override is missing from the new selection - it needs to be deleted
  const isForDeleteOverride = (override: ExistingVendorPurposeOverride) => !selectedPurposesIds.includes(override.metadata_purpose_id);
  // If override is not present in the previous overrides - it needs to be added
  const isForNewOverride = (pId: string) => !previousOverridesForVendor.find(override => override.metadata_purpose_id === pId);

  const toDelete = previousOverridesForVendor.filter(isForDeleteOverride).map(o => o.id);
  const toAdd = selectedPurposesIds.filter(isForNewOverride);

  return { toAdd, toDelete };
};
