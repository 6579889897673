import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { Purpose } from '@types';
import { useFetchAllPaginatedResults } from '../../useFetchAllPaginatedResults.hook';

export const useGlobalPurposes = () => {
  const { fetchAllPaginatedResults } = useFetchAllPaginatedResults();

  const fetchPurposes = async () => {
    return fetchAllPaginatedResults<Purpose>('metadata/global-purposes?$translations=true');
  };

  return useQuery<Purpose[], AxiosError>(['global-purposes'], fetchPurposes);
};
