import { Vendor, VendorInitialValuesType, VendorStorage } from '@types';

// Function to map vendor data that is fetched from the API to the form data that is displayed in the form
export const vendorToFormData = (vendor: Vendor, vendorStorage: VendorStorage[] = []) => {
  const mappedVendor = {
    ...vendor,
    hosts: vendor?.hosts || [],
    contact_information:
      vendor?.address &&
      Object.keys(vendor?.address)?.map(language => ({
        language,
        address: (vendor.address && vendor.address[language]) || '',
        contactEmail: (vendor.contact && vendor.contact[language]) || '',
      })),
    cookie_information: vendorStorage,
  } as VendorInitialValuesType;

  return mappedVendor;
};
