import { useMemo } from 'react';
import { VendorFilterType } from '@types';
import { Vendor } from 'types/vendor';
import { isCustomVendor } from './isCustomVendor';

/**
 * Filter the list of vendors based on the selected filter type (all, custom, IAB, or ATP).
 *
 * @param vendors - The list of vendors.
 * @param vendorsFilter - The filter type to apply.
 * @param organizationId - The current organization's ID.
 * @returns The filtered list of vendors.
 */
export const useFilteredVendors = (vendors: Vendor[], vendorsFilter: string, organizationId: string): Vendor[] => {
  return useMemo(() => {
    if (!vendors) return [];
    const isATPVendor = (vendor: Vendor) => Number.isInteger(vendor.namespaces?.google);

    const isIABVendor = (vendor: Vendor) => vendor.namespaces?.iab2 > 0;

    switch (vendorsFilter) {
      case VendorFilterType.ALL:
        return vendors;
      case VendorFilterType.CUSTOM:
        return vendors.filter(vendor => isCustomVendor(vendor, organizationId));
      case VendorFilterType.IAB:
        return vendors.filter(isIABVendor);
      case VendorFilterType.ATP:
        return vendors.filter(isATPVendor);
      default:
        return vendors;
    }
  }, [vendors, vendorsFilter, organizationId]);
};
