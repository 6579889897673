import { OperatorType } from '@didomi/cmp-generator';
import * as Yup from 'yup';

interface VendorFormOptions {
  atLeastOnePurposeRequired: boolean;
}

export const getVendorFormSchema = ({ atLeastOnePurposeRequired }: VendorFormOptions) => {
  const baseSchema = Yup.object().shape({
    name: Yup.string().trim().required('Please add a name to the vendor'),
    type: Yup.mixed<OperatorType>().oneOf(Object.values(OperatorType)),
    default_purposes_id: Yup.array()
      .of(Yup.string())
      .when('legitimate_interest_purposes_id', {
        is: (liPurposesIds: string[]) => atLeastOnePurposeRequired && liPurposesIds?.length === 0,
        then: Yup.array().of(Yup.string()).required().min(1, 'Please select at least one purpose'),
        otherwise: Yup.array().of(Yup.string()).nullable(),
      }),
    legitimate_interest_purposes_id: Yup.array().of(Yup.string()).nullable(),
    contact_information: Yup.array().nullable(),
    country_id: Yup.string().nullable(),
    protection_id: Yup.string().nullable(),
    taxonomy_id: Yup.string().nullable(),
    cookie_max_duration: Yup.number().nullable(),
    cookie_max_duration_unit: Yup.string().default('months').nullable(),
    uses_non_cookie_access: Yup.boolean().nullable(),
    cookie_information: Yup.array().nullable(),
    links: Yup.object().shape({
      privacypolicy: Yup.string().url('You must provide a valid website (e.g. https://www.website.com)').nullable(),
      website: Yup.string().url('You must provide a valid website (e.g. https://www.website.com)').nullable(),
      optout: Yup.string().url('You must provide a valid website (e.g. https://www.website.com)').nullable(),
      terms: Yup.string().url('You must provide a valid website (e.g. https://www.website.com)').nullable(),
      dpa: Yup.string().url('You must provide a valid website (e.g. https://www.website.com)').nullable(),
      protection: Yup.string().url('You must provide a valid website (e.g. https://www.website.com)').nullable(),
      subprocessors: Yup.string().url('You must provide a valid website (e.g. https://www.website.com)').nullable(),
    }),
    category_id: Yup.string().nullable().required('Please select a provider'),
    namespaces: Yup.object()
      .shape({
        custom: Yup.string()
          .matches(/^([A-Za-z0-9-_]+)$/, 'You must provide a valid custom SDK ID')
          .nullable(),
      })
      .nullable(),
    spi_purposes_id: Yup.array().of(Yup.string()).nullable(),
    hosts: Yup.array().nullable(),
    iab_tcf_vendor_id: Yup.string().matches(/^\d+$/).nullable(),
  });

  return baseSchema;
};
