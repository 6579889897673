import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { ExistingPurposeRegulationOverride } from '@types';

export const useEditPurposeRegulationsOverride = (
  options?: UseMutationOptions<AxiosResponse<ExistingPurposeRegulationOverride>, AxiosError, Partial<ExistingPurposeRegulationOverride>>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    async (override: Partial<ExistingPurposeRegulationOverride>) => {
      return axiosWithInterceptors.patch(`${CONFIG.environmentConfig.apiBaseUrl}metadata/purposes-regulations-overrides/${override.id}?$translations=true`, override);
    },
    {
      ...options,
      onSuccess: (updatedOverride: AxiosResponse<ExistingPurposeRegulationOverride>, variables, context) => {
        queryClient.setQueryData<ExistingPurposeRegulationOverride[]>(['purposes-regulations-overrides', organizationId], overrides => {
          return overrides?.map(override => (override.id === updatedOverride.data.id ? updatedOverride.data : override));
        });

        if (options?.onSuccess) {
          options.onSuccess(updatedOverride, variables, context);
        }
      },
    },
  );
};
