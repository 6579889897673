import React, { useMemo } from 'react';
import { supportedLanguages } from '@didomi/helpers';
import {
  DidomiButton,
  DidomiSkeleton,
  DidomiCollapsibleSection,
  DidomiHintbox,
  DidomiSelect,
  DidomiSelectOption,
  DidomiSelectOptions,
  DidomiSelectionBlock,
  DidomiSwitchField,
  DidomiChip,
} from '@didomi/ui-atoms-react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LanguagesField, SanitizedTextInputField } from '@components';
import { useSelectWithAllState } from '@hooks';
import { PurposeRequestBody, PurposeViewMode, Regulation } from '@types';

const PurposeSchema = Yup.object().shape({
  languages: Yup.array().required('You need to define at least one language').min(1, 'You need to define at least one language'),
  sdk_id: Yup.string().matches(/^([A-Za-z0-9-_]+)$/, 'You must provide a valid custom SDK ID'),
  is_spi: Yup.boolean(),
});

type PurposeFormProps = {
  initialValues: any;
  onSubmit?: (newPurpose: PurposeRequestBody) => void;
  onCancel?: () => void;
  isLoading?: boolean;
  regulations?: Regulation[];
  viewMode?: PurposeViewMode;
};

/**
 * Add/Edit Purpose Form
 */
export const PurposeForm = ({ initialValues, onCancel, onSubmit, isLoading = false, regulations, viewMode = 'edit' }: PurposeFormProps): JSX.Element => {
  const regulationValues = useMemo(() => {
    return regulations?.map(r => r.id);
  }, [regulations]);

  const {
    fieldValue: regulationFieldValue,
    selectValue: regulationSelectValue,
    onValueChange: handleRegulationsChange,
  } = useSelectWithAllState(initialValues?.regulations_id, regulationValues);

  const handleSubmit = submitData => {
    const description = submitData?.languages?.reduce((result, value) => ({ ...result, [value.language?.code]: value.description }), {});
    const details = submitData?.languages?.reduce((result, value) => ({ ...result, [value.language?.code]: value.details }), {});
    const purposeDetails = submitData?.sdk_id
      ? {
          description,
          details,
          regulations_id: regulationFieldValue,
          is_spi: submitData?.is_spi,
          namespaces: {
            custom: submitData?.sdk_id,
          },
        }
      : { description, details, regulations_id: regulationFieldValue, is_spi: submitData?.is_spi };

    onSubmit(purposeDetails);
  };

  let languages = [];

  if (initialValues?.description) {
    languages = Object.keys(initialValues.description)
      .map(lang => ({
        language: supportedLanguages.find(sL => sL.code === lang),
        description: initialValues.description[lang],
        details: initialValues.details?.[lang],
      }))
      .filter(item => item.language);
  }

  const mappedInitialValues = {
    sdk_id: initialValues?.sdk_id,
    is_spi: initialValues?.is_spi,
    languages,
  };

  return (
    <Formik className="w-full" initialValues={mappedInitialValues} validationSchema={PurposeSchema} onSubmit={handleSubmit} enableReinitialize validateOnMount={true}>
      {({ isValid, values }) => (
        <DidomiSkeleton className="w-full" isLoading={isLoading} variant="layout" data-testid={`preference-form-skeleton-loading-${isLoading}`}>
          <Form className="w-full mt-s flex flex-col mb-s">
            <LanguagesField name="languages" className="w-full mb-s" isLoading={isLoading} viewOnlyMode={viewMode !== 'edit'} />
            <div className="max-w-[512px] flex flex-col gap-xs mb-m">
              <DidomiCollapsibleSection label="Purpose type">
                <DidomiHintbox variant="neutral" className="mt-xxs mb-xxs">
                  When creating a purpose, you can define its type as either <b>Purpose</b> or <b>SPI</b>.
                </DidomiHintbox>
                <DidomiSelectionBlock className="w-full h-full flex flex-col" selected={values.is_spi} disabled={viewMode !== 'edit'}>
                  <div className="flex items-center gap-xs text-neutral-gray-7">
                    <DidomiChip label="SPI" variation="basic" basic-type="info-alternative" />
                    <div>
                      <div className="text-body-small font-semibold">Set purpose as SPI</div>
                      <span className="text-body-extra-small">Sensitive Personal Information</span>
                    </div>
                    <DidomiSwitchField
                      data-testid="spi-switch"
                      name="is_spi"
                      className="!w-auto ml-auto"
                      valueSelected={true}
                      valueNotSelected={false}
                      hideErrorMessage
                      disabled={viewMode !== 'edit'}
                    ></DidomiSwitchField>
                  </div>
                </DidomiSelectionBlock>
              </DidomiCollapsibleSection>

              <DidomiCollapsibleSection data-testid="regulations-settings-collapse" label="Regulation settings">
                <DidomiHintbox variant="neutral" className="mt-xxs mb-xxs">
                  When you add a vendor to a consent notice, selecting a regulation below will allow users to grant or deny consent for that vendor&apos;s data processing.
                </DidomiHintbox>
                <div>
                  {regulations?.length && (
                    <DidomiSelect
                      data-testid="multi-regulations-select"
                      value={regulationSelectValue}
                      className="mt-s block !max-w-[520px]"
                      multi
                      label="Regulations"
                      disabled={viewMode === 'view-only'}
                      onValueChange={e => handleRegulationsChange(e.detail as string[])}
                    >
                      <DidomiSelectOptions>
                        <DidomiSelectOption value="all" label="All" />
                        {regulations.map(r => (
                          <DidomiSelectOption key={r.id} value={r.id} label={r.id.toUpperCase()} />
                        ))}
                      </DidomiSelectOptions>
                    </DidomiSelect>
                  )}
                </div>
              </DidomiCollapsibleSection>

              <DidomiCollapsibleSection label="Advanced settings">
                <DidomiHintbox variant="neutral" className="mt-xxs mb-xxs">
                  You can specify an SDK ID that maps to this purpose. Only do it if you are importing existing consent notices from JSON configurations and need to force set the
                  SDK ID of this purpose to a custom purpose ID used in your JSON configuration.
                </DidomiHintbox>
                <SanitizedTextInputField
                  name="sdk_id"
                  className="mt-s"
                  label="Custom SDK ID"
                  placeholder="Enter a custom SDK ID…"
                  data-skeleton={isLoading}
                  disabled={viewMode !== 'edit'}
                />
              </DidomiCollapsibleSection>
            </div>
            {viewMode !== 'view-only' && (
              <div className="w-full flex justify-start">
                <DidomiButton type="button" variant="secondary" className="mr-xs" disabled={isLoading} onClick={onCancel} data-skeleton>
                  Cancel
                </DidomiButton>
                <DidomiButton data-tracking="purpose-save-button" type="submit" data-skeleton disabled={!isValid || isLoading}>
                  Save
                </DidomiButton>
              </div>
            )}
          </Form>
        </DidomiSkeleton>
      )}
    </Formik>
  );
};
