import { OperatorType } from '@didomi/cmp-generator';

export type VendorLink = {
  website: string;
  privacypolicy: string;
  optout: string;
  terms: string;
  dpa: string;
  protection: string;
  subprocessors: string;
};

export type VendorLinkReadable = {
  label: string;
  value: string;
};

export enum VendorLinkType {
  dpa = 'Data Processing',
  optout = 'Opt-out',
  privacypolicy = 'Privacy Policy',
  protection = 'Applicable protection',
  subprocessors = 'List of Subconstructors',
  terms = 'Terms & Conditions',
  website = 'Website',
}

export enum VendorType {
  CUSTOM = 'custom',
  DIDOMI = 'didomi',
  IAB2 = 'iab2',
  GOOGLE = 'google',
  BUILT_IN = 'built_in',
}

export type CollectedVendors = {
  canonical_metadata_partner_id?: string | null;
  canonical_metadata_partner_name?: string | null;
  functional_namespace?: string | null;
  id?: string | null;
  key?: string | null;
  name?: string | null;
  namespaces?: null | string;
  notes?: string | null;
  organization_id?: string;
  url_sld?: string | null;
  url_host: string | null;
  vendor_iabv2_id?: null | number;
  vendor_tcfv2_data?: null | number;
  nameOrKey?: string | null;
  url?: string;
};

export type DraftVendor = {
  key?: string | number;
  vendor?: VendorInitialValuesType;
};

export type VendorInitialValuesType = {
  name: string | { [language: string]: string };
  type: OperatorType;
  default_purposes_id?: string[];
  legitimate_interest_purposes_id?: string[];
  purposes_id?: string[];
  contact_information: any[];
  country_id: string;
  protection_id: string;
  taxonomy_id: string;
  cookie_max_duration: number | null;
  cookie_max_duration_unit: string;
  uses_non_cookie_access: boolean;
  cookie_information: any[];
  links: {
    privacypolicy: string;
    website: string;
    optout: string;
    terms: string;
    dpa: string;
    protection: string;
    subprocessors: string;
  };
  category_id: string;
  namespaces?: {
    iab?: number;
    iab2?: number;
    didomi?: string;
    custom?: string;
    google?: number;
  };
  spi_purposes_id?: string[];
  hosts?: string[];
};

export type Vendor = {
  id: string;
  type: OperatorType;
  sdk_id: string;
  address?: { [language: string]: string } | string;
  country_id?: string;
  protection_id?: string;
  links: VendorLink;
  name: { [language: string]: string } | string;
  tags: [string];
  contact: { [language: string]: string };
  website: string;
  website_optout: string;
  hosts: string[];
  url_patterns: [string];
  urls: [string];
  taxonomy_id: string;
  organization_id: string;
  category_id: string;
  country: string;
  created_at: Date;
  updated_at: Date;
  version: number;
  namespaces: {
    iab?: number;
    iab2?: number;
    didomi?: string;
    custom?: string;
    google?: number;
  };
  default_purposes_id: string[];
  legitimate_interest_purposes_id: string[];
  cookie_max_duration: number;
  cookie_max_duration_unit: string;
  uses_non_cookie_access: boolean;
  deprecated: boolean;
  notes: string;
  spi_purposes_id?: string[];
};

export interface ExtendedVendorUi extends Vendor {
  default_purposes_name?: string[];
  legitimate_interest_purposes_name?: string[];
  links_readable: VendorLinkReadable[];
}

export type VendorStorage = {
  created_at: string;
  domain: string;
  id: string;
  identifier: string;
  max_age: number;
  max_age_unit: string;
  organization_id: string;
  partner_id: string;
  purposes_id: string[];
  type: string;
  updated_at: string;
  version: number;
};

export interface VendorRequestBody {
  address?: { [language: string]: string };
  category_id?: string;
  contact?: { [language: string]: string };
  cookie_max_duration?: number;
  cookie_max_duration_unit?: string;
  country_id?: string;
  default_purposes_id?: string[];
  legitimate_interest_purposes_id?: string[];
  links: {
    dpa?: string;
    optout?: string;
    privacypolicy: string;
    protection?: string;
    subprocessors?: string;
    terms?: string;
    website?: string;
  };
  name: string;
  namespaces?: { custom?: string; iab2?: string };
  organization_id: string;
  protection_id?: string;
  taxonomy_id?: string;
  uses_non_cookie_access?: boolean;
  spi_purposes_id?: string[];
  hosts?: string[];
}

export interface VendorStorageRequestBody {
  domain: string;
  identifier: string;
  max_age: number;
  max_age_unit: string;
  organization_id: string;
  partner_id?: string;
  purposes_id?: string[];
  type: string;
}
