import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, Vendor } from '@types';

/**
 * Fetches the list of vendors corresponding to the loaded organization.
 *
 * By default, this hook will fetch both custom vendors (specific to the organization)
 * and global vendors (available across all organizations).
 *
 * - If `fetchGlobalVendors` is set to `false`, only custom vendors will be fetched.
 * - We have a dependency on the useAllPurposes hook, as we want the purposes to be
 *   loaded first to map the purpose values with the corresponding names.
 *
 * @param {Object} options - Options for controlling vendor fetching behavior.
 * @param {boolean} [options.fetchGlobalVendors=true] - Flag to indicate whether to fetch global vendors
 *   in addition to the organization's custom vendors.
 *
 * @returns {object} Query result from react-query with combined vendor data and additional metadata.
 */
export const useVendors = ({ fetchGlobalVendors = true } = {}) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  /**
   * Fetch vendors (both custom and global, or just custom based on the flag).
   * If `fetchGlobalVendors` is `true`, it makes two separate API calls:
   * - One for the organization's custom vendors.
   * - One for the global vendors.
   * If `fetchGlobalVendors` is `false`, only the custom vendors are fetched.
   */
  const fetchVendors = async () => {
    try {
      let customVendors, globalVendors;

      if (fetchGlobalVendors) {
        // Fetch both custom and global vendors in parallel
        [customVendors, globalVendors] = await Promise.all([
          axiosWithInterceptors.get<PaginatedResponse<Vendor>>(
            `${CONFIG.environmentConfig.apiBaseUrl}metadata/partners?$translations=true&$limit=5000&organization_id=${organizationId}`,
          ),
          axiosWithInterceptors.get<PaginatedResponse<Vendor>>(`${CONFIG.environmentConfig.apiBaseUrl}metadata/partners?$translations=true&$limit=5000&organization_id=${null}`),
        ]);
      } else {
        // Only fetch custom vendors
        customVendors = await axiosWithInterceptors.get<PaginatedResponse<Vendor>>(
          `${CONFIG.environmentConfig.apiBaseUrl}metadata/partners?$translations=true&$limit=5000&organization_id=${organizationId}`,
        );
        globalVendors = { data: { data: [], total: 0 } }; // Empty data for global vendors
      }

      // Combine the results from both responses (custom + global vendors)
      const combinedData = [...customVendors.data.data, ...globalVendors.data.data];

      // Calculate the total number of vendors
      const total = (customVendors.data.total || 0) + (globalVendors.data.total || 0);
      const combinedMetadata = {
        ...customVendors.data,
        total,
      };

      // Return the combined metadata and the mapped vendors
      return {
        ...combinedMetadata,
        data: combinedData,
      };
    } catch (error) {
      // Catch any errors and throw a new error with the error message
      throw new Error(error.message);
    }
  };

  // Using react-query to fetch vendors based on the organization ID and the fetchGlobalVendors flag
  return useQuery<PaginatedResponse<Vendor>, AxiosError>(['vendors', organizationId, fetchGlobalVendors], fetchVendors);
};
