import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DidomiErrorState } from '@didomi/ui-atoms-react';
import { useSnackbar } from '@didomi/utility-react';
import { VendorForm } from '@components';
import { useEditVendor, useVendor, useCreateVendorStorage, useVendorStorages, useEditVendorStorage, useDeleteVendorStorage, useSyncPatchVendorWithComplianceReport } from '@hooks';
import { LoadingModal } from '@modals';
import { VendorRequestBody } from '@types';
import { vendorToFormData } from '@utils';

/**
 * Edit Vendor Page
 */
export const EditVendor = ({ viewOnlyMode = false }): JSX.Element => {
  const { displaySnackbar } = useSnackbar();
  const [loadingStatus, setLoadingStatus] = useState<string>(null);
  const navigate = useNavigate();
  const { vendorId } = useParams();

  const { data: vendor, isLoading: loadingVendor, error: errorLoadingVendor } = useVendor(vendorId);

  const { data: vendorStorages } = useVendorStorages(vendor?.id);

  const { mutateAsync: editVendor } = useEditVendor(vendorId);
  const { mutateAsync: syncVendorWithCr } = useSyncPatchVendorWithComplianceReport();

  const { mutateAsync: createVendorStorage } = useCreateVendorStorage();
  const { mutateAsync: editVendorStorage } = useEditVendorStorage();
  const { mutateAsync: deleteVendorStorage } = useDeleteVendorStorage();

  const mappedVendor = useMemo(() => vendorToFormData(vendor, vendorStorages?.data), [vendor, vendorStorages]);

  const handleVendorEdition = async (newVendorData: VendorRequestBody, vendorStorages: any[]) => {
    setLoadingStatus('saving-vendor');
    try {
      const vendorCreated = await editVendor(newVendorData);
      try {
        await syncVendorWithCr(vendorCreated.data);
      } catch (e) {
        displaySnackbar('There was an error adding the vendor. You should see it available within the hour', { icon: 'danger-light', variant: 'message' });
      }

      if (vendorStorages?.length) {
        setLoadingStatus('saving-vendor-storages');

        for (const vendorStorage of vendorStorages) {
          if (vendorStorage.created) {
            delete vendorStorage.created;
            delete vendorStorage.deleted;
            delete vendorStorage.id;
            await createVendorStorage({ ...vendorStorage, partner_id: vendorCreated.data.id });
          } else if (vendorStorage.deleted) {
            // If the deleted vendor actually exists
            if (!vendorStorage.created) {
              await deleteVendorStorage(vendorStorage.id);
            }
          } else {
            if (vendorStorage.edited) {
              delete vendorStorage.edited;
              delete vendorStorage.created;
              delete vendorStorage.deleted;
              await editVendorStorage({ vendorStorageId: vendorStorage.id, vendorStorage });
            }
          }
        }
      }
      setLoadingStatus(null);
      displaySnackbar(`Vendor "${vendorCreated?.data?.name}" has been updated successfully!`, { icon: 'check' });
      navigate('/vendors');
    } catch (e) {
      setLoadingStatus(null);
      displaySnackbar('There was an error editing the vendor', { icon: 'danger-light', variant: 'error' });
    }
  };

  if (!loadingVendor && !!errorLoadingVendor) {
    if (errorLoadingVendor.response.statusText === 'Not Found') {
      return (
        <DidomiErrorState illustrationName="no-matches-found" className="h-full">
          <div slot="title">The vendor does not exist in our records</div>
        </DidomiErrorState>
      );
    }
  }

  return (
    <main className="w-full pb-10">
      <VendorForm
        initialValues={mappedVendor}
        onSubmit={handleVendorEdition}
        onCancel={() => navigate('/vendors')}
        isLoading={loadingVendor}
        viewMode={viewOnlyMode ? 'view-only' : 'edit'}
      />
      <LoadingModal
        data-cy="edit-vendor-saving"
        isOpen={!!loadingStatus}
        title={loadingStatus === 'saving-vendor-storage' ? 'We are updating your storage list...' : 'We are updating your vendor...'}
      />
    </main>
  );
};
