import React, { useRef, useState } from 'react';
import { DidomiButtonGroup, DidomiCollapse, DidomiIconButton, DidomiLanguageFlag } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { DataLanguage } from '@types';

type LanguageCardProps = {
  'data-testid'?: string;
  language: DataLanguage;
  description: string;
  details?: string;
  isLoading?: boolean;
  className?: string;
  onEdit: (code: string) => void;
  onRemove: (code: string) => void;
  viewOnlyMode?: boolean;
};

const SECTION_DETAILS_HEIGHT = 134;

export const LanguageCard = ({
  'data-testid': dataTestid,
  isLoading,
  className,
  language,
  description,
  details,
  onEdit,
  onRemove,
  viewOnlyMode = false,
}: LanguageCardProps): JSX.Element => {
  const [isCardExpanded, setIsCardExpanded] = useState(false);
  const sectionDetailsRef = useRef(null);

  /* istanbul ignore next - can't be tested in jsdom */
  const hasDetailOverflow = !!sectionDetailsRef?.current?.scrollHeight && SECTION_DETAILS_HEIGHT < sectionDetailsRef?.current?.scrollHeight;

  return (
    <DidomiCollapse
      data-testid={dataTestid}
      data-cy={'langualge-card-' + language?.code}
      isExpanded={isCardExpanded}
      minHeight={192}
      className="w-full max-w-[512px] border-[4px] border-neutral-gray-1 border-solid rounded-lg shadow-language-card"
      data-skeleton={isLoading}
    >
      <div className={tx(className, 'relative h-full w-full flex py-s border-1 border-solid border-neutral-gray-5 rounded-lg', { 'pb-[40px]': hasDetailOverflow })}>
        <section className="min-w-[132px] w-[132px] h-full flex flex-col justify-start items-center border-r-1 border-solid border-neutral-gray-5 px-s">
          <DidomiLanguageFlag language={language.code} fallbackName={language.longName} displayName className="h-full" />
          {!viewOnlyMode && (
            <DidomiButtonGroup>
              <DidomiIconButton type="button" data-testid={dataTestid + '-edit'} title="Edit" variant="rounded" icon="edit" onClick={() => onEdit(language.code)} />
              <DidomiIconButton type="button" data-testid={dataTestid + '-delete'} title="Delete" variant="rounded" icon="delete" onClick={() => onRemove(language.code)} />
            </DidomiButtonGroup>
          )}
        </section>

        <div className={tx({ 'overflow-y-hidden': !isCardExpanded })}>
          <section ref={sectionDetailsRef} className="h-full max-w-[380px] justify-start px-s text-primary-blue-6">
            <div>
              <div className="font-sans  text-[10px] leading-[13px] font-bold uppercase mb-xxxs">Name</div>
              <div className="text-body-small">{description}</div>
            </div>
            <div className="mt-s">
              <div className="font-sans  text-[10px] leading-[13px] font-bold uppercase mb-xxxs">Description</div>
              <div className="text-body-small break-words">{details || '-'}</div>
            </div>
          </section>
        </div>
        {
          /* istanbul ignore next - can't be tested in jsdom */ hasDetailOverflow && (
            <DidomiIconButton
              type="button"
              data-testid={dataTestid + '-expand'}
              title={isCardExpanded ? 'Collapse' : 'Expand'}
              className={tx('absolute left-[296px] bottom-[8px] hover:bg-white rounded-full transition-transform', { 'rotate-180': isCardExpanded })}
              variant="rounded"
              size="small"
              icon="down-on"
              onClick={() => setIsCardExpanded(e => !e)}
            />
          )
        }
      </div>
    </DidomiCollapse>
  );
};
