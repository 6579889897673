import { Vendor } from 'types/vendor';

/**
 * Check if a vendor is custom. A custom vendor is not a Didomi vendor and belongs to the current organization.
 *
 * @param vendor - The vendor to check.
 * @param organizationId - The current organization's ID.
 * @returns True if the vendor is custom, false otherwise.
 */
export const isCustomVendor = (vendor: Vendor, organizationId: string): boolean => {
  const isDidomiVendor = (vendor: Vendor) => typeof vendor.namespaces?.didomi === 'string';
  return !isDidomiVendor(vendor) && vendor.organization_id === organizationId;
};
