import { Purpose, PurposeFilterType } from '@types';

const isCustomPurpose = (purpose: Purpose) => purpose.organization_id !== null;
const isIABPurpose = (purpose: Purpose) => purpose.namespaces?.iab2 > 0 || purpose.namespaces?.iab2_special_feature > 0;
const isGlobalPurpose = (purpose: Purpose) => purpose.organization_id === null;

export const getFilteredPurposes = (purposes: Purpose[] = [], purposesFilter: string) => {
  switch (purposesFilter) {
    case PurposeFilterType.ALL:
      return purposes;
    case PurposeFilterType.GLOBAL:
      return purposes.filter(isGlobalPurpose);
    case PurposeFilterType.CUSTOM:
      return purposes.filter(isCustomPurpose);
    case PurposeFilterType.IAB:
      return purposes.filter(isIABPurpose);
    default:
      return purposes;
  }
};
