import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { ExistingPurposeRegulationOverride } from '@types';

export const useDeletePurposeRegulationsOverride = (options?: UseMutationOptions<AxiosResponse<ExistingPurposeRegulationOverride>, AxiosError, string>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    async (overrideId: string) => {
      return axiosWithInterceptors.delete(`${CONFIG.environmentConfig.apiBaseUrl}metadata/purposes-regulations-overrides/${overrideId}`);
    },
    {
      ...options,
      onSuccess: (deletedOverride: AxiosResponse<ExistingPurposeRegulationOverride>, variables, context) => {
        queryClient.setQueryData<ExistingPurposeRegulationOverride[]>(['purposes-regulations-overrides', organizationId], overrides => {
          return overrides?.filter(o => o.id !== deletedOverride.data.id);
        });

        if (options?.onSuccess) {
          options.onSuccess(deletedOverride, variables, context);
        }
      },
    },
  );
};
