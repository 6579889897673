import React from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { useSnackbar } from '@didomi/utility-react';
import { PurposeForm } from '@components';
import { useBackNavigation, useCreatePurpose, useRegulations } from '@hooks';
import { LoadingModal } from '@modals';

/**
 * Add Purpose Page
 */
export const AddPurpose = (): JSX.Element => {
  const { displaySnackbar } = useSnackbar();
  const navigateBack = useBackNavigation();
  const { data: regulations, isLoading: isLoadingRegulations } = useRegulations();

  const { mutate: createPurpose, isLoading: isCreatingPurpose } = useCreatePurpose({
    onSuccess: ({ data: newPurpose }) => {
      displaySnackbar(`Purpose "${getTranslatedValue(newPurpose.description)}" has been created successfully!`, { icon: 'check' });
      navigateBack('/purposes');
    },
    onError: () => {
      displaySnackbar('There was an error adding the purpose', { icon: 'danger-light', variant: 'error' });
    },
  });

  return (
    <main className="w-full pb-10">
      <PurposeForm initialValues={{}} regulations={regulations} isLoading={isLoadingRegulations} onSubmit={createPurpose} onCancel={() => navigateBack('/purposes')} />
      <LoadingModal data-cy="add-purpose-saving" isOpen={isCreatingPurpose} title="We are creating your purpose..." />
    </main>
  );
};
