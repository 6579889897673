import React, { useMemo, useRef } from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import {
  DidomiModal,
  DidomiModalHeader,
  DidomiModalActions,
  DidomiButton,
  DidomiModalContent,
  DidomiSelectField,
  DidomiSelectOption,
  DidomiSelectOptions,
  DidomiSkeleton,
  DidomiNumberInputField,
  DidomiAutocompleteWithSearchField,
} from '@didomi/ui-atoms-react';
import { useActiveOrganization } from '@didomi/utility-react';
import { tx } from '@twind/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SanitizedTextInputField } from '@components';
import { useAllPurposes, useGlobalPurposes } from '@hooks';
import { VendorStorageType } from '@types';
import { COOKIE_DURATIONS, DEPRECATED_PURPOSES_IDS } from '@utils';

type AddVendorStorageModalProps = {
  isOpen: boolean;
  isEditing: boolean;
  initialValues: any;
  onConfirm: (newCookieStorage) => void;
  onCancel: () => void;
  onChange: (e: CustomEvent) => void;
};

const VendorStorageSchema = Yup.object().shape({
  type: Yup.string().trim().oneOf(Object.keys(VendorStorageType)).required('Required'),
  identifier: Yup.string().trim().required('Required'),
  deleted: Yup.boolean().default(true),
  domain: Yup.string()
    .trim()
    .nullable()
    .when('type', {
      is: 'cookie',
      then: Yup.string().trim().nullable().required('Required'),
    })
    .when('type', {
      is: 'web-storage',
      then: Yup.string().trim().nullable().required('Required'),
    }),
  max_age: Yup.number()
    .typeError('Must be a number')
    .nullable()
    .when('type', {
      is: 'cookie',
      then: Yup.number().nullable().typeError('Must be a number').required('Required'),
      otherwise: Yup.number().nullable(),
    }),
  max_age_unit: Yup.string()
    .default('months')
    .when('type', {
      is: 'cookie',
      then: Yup.string().nullable().default('months').oneOf(['seconds', 'days', 'months'], 'Select a value').required('Required'),
      otherwise: Yup.string().nullable(),
    }),
  purposes_id: Yup.array(),
});

export const AddVendorStorageModal = ({ isOpen, initialValues, isEditing, onCancel, onConfirm }: AddVendorStorageModalProps) => {
  const formikRef = useRef(null);
  const { organizationId } = useActiveOrganization();
  const { data: allPurposes, isLoading: isLoadingPurposesList } = useAllPurposes();
  const { data: globalPurposes, isLoading: isLoadingGlobalPurposes } = useGlobalPurposes();

  const purposeToSelect = useMemo(() => {
    if (!allPurposes?.data || !globalPurposes) return [];
    const purposesIds = (initialValues && initialValues.purposes_id) || [];

    const orgPurposes = allPurposes.data.filter(p => p.organization_id === organizationId);
    const combinedPurposes = orgPurposes.concat(globalPurposes);

    // Filter out deprecated purposes that are not selected(e.g. if deprecated purpose is selected, it should be displayed)
    return combinedPurposes.filter(p => !DEPRECATED_PURPOSES_IDS.includes(p.id) || (DEPRECATED_PURPOSES_IDS.includes(p.id) && purposesIds.includes(p.id)));
  }, [allPurposes?.data, globalPurposes, initialValues, organizationId]);

  return (
    <DidomiModal
      isOpen={isOpen}
      variant="slim"
      data-testid="add-vendor-storage-modal"
      data-cy="add-vendor-storage-modal"
      onOpenChange={
        /* istanbul ignore next - can't be tested with the mocks */ e => {
          if (!e.detail) {
            formikRef.current?.resetForm();
            onCancel();
          }
        }
      }
    >
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        validationSchema={VendorStorageSchema}
        onSubmit={(storage, { resetForm }) => {
          resetForm();
          onConfirm({ ...storage, max_age: storage.max_age ? parseInt(storage.max_age, 10) : null });
        }}
        enableReinitialize
        validateOnMount={true}
      >
        {({ resetForm, values }) => (
          <Form style={{ width: '100%' }} data-testid="add-vendor-storage-form" data-cy="add-vendor-storage-form">
            <DidomiModalHeader modalTitle={isEditing ? 'Update storage' : 'Add a new storage'}></DidomiModalHeader>
            <DidomiModalContent style={{ marginTop: '24px' }}>
              <div>
                <DidomiSelectField name="type" label="Type of storage" placeholder="Choose a type of storage">
                  <DidomiSelectOptions>
                    {Object.entries(VendorStorageType).map(([sK, sT]) => (
                      <DidomiSelectOption key={sK} value={sK} label={sT} />
                    ))}
                  </DidomiSelectOptions>
                </DidomiSelectField>
                <SanitizedTextInputField name="identifier" label="Name *" placeholder="Enter a name" />
                {values['type'] !== 'app_storage' && <SanitizedTextInputField name="domain" label="Domain *" placeholder="Enter a domain" />}
                {values['type'] === 'cookie' && (
                  <div style={{ width: '100%', display: 'flex' }} className={tx('w-full flex items-end')}>
                    <div style={{ marginRight: '8px' }}>
                      <DidomiNumberInputField min="0" name="max_age" label="Cookie max duration *" placeholder="Enter value" />
                    </div>
                    <DidomiSelectField name="max_age_unit" placeholder="Select cookie duration">
                      <DidomiSelectOptions>
                        {COOKIE_DURATIONS.map(cD => (
                          <DidomiSelectOption key={'cookie-duration-' + cD.value} value={cD.value} label={cD.name} />
                        ))}
                      </DidomiSelectOptions>
                    </DidomiSelectField>
                  </div>
                )}
                <DidomiSkeleton isLoading={isLoadingPurposesList || isLoadingGlobalPurposes}>
                  <DidomiAutocompleteWithSearchField
                    name="purposes_id"
                    multi
                    label="Used for the following purposes"
                    placeholder="Select the vendor’s purposes"
                    items={purposeToSelect}
                    searchField="description"
                    searchFieldModifier={getTranslatedValue}
                  />
                </DidomiSkeleton>
              </div>
            </DidomiModalContent>
            <DidomiModalActions>
              <DidomiButton
                type="button"
                variant="secondary"
                onClick={() => {
                  resetForm();
                  onCancel();
                }}
              >
                Cancel
              </DidomiButton>
              <DidomiButton type="submit">
                <span>{isEditing ? 'Update' : 'Add'}</span>
              </DidomiButton>
            </DidomiModalActions>
          </Form>
        )}
      </Formik>
    </DidomiModal>
  );
};
