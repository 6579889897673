import { useMemo } from 'react';
import { Partner, PartnerPurposeProcessor, Purpose } from '@didomi/cmp-generator';
import { Vendor } from '@types';
import { useVendorsPurposesOverrides } from './useVendorsPurposesOverrides.hook';
import { useAllPurposes } from '../purposes/useAllPurposes.hook';

/**
 * Process passed vendors in order to apply purposes overrides
 */
export const useVendorsWithOverrides = (vendors: Vendor[]) => {
  const purposesQuery = useAllPurposes();
  const partnerPurposesOverridesQuery = useVendorsPurposesOverrides();

  const isLoading = purposesQuery.isLoading || partnerPurposesOverridesQuery.isLoading;

  const processedVendors = useMemo(() => {
    if (isLoading || !vendors) {
      return [];
    }

    const purposes = purposesQuery.data.data;
    const purposeOverrides = partnerPurposesOverridesQuery.data.purposeOverrides;
    const legIntPurposeOverrides = partnerPurposesOverridesQuery.data.legIntPurposeOverrides;

    return PartnerPurposeProcessor.processPartnerPurposes(vendors as Partner[], purposes as unknown as Purpose[], null, {
      purposeOverrides,
      legIntPurposeOverrides,
    }) as unknown as Vendor[];
  }, [isLoading, vendors, purposesQuery.data?.data, partnerPurposesOverridesQuery.data.legIntPurposeOverrides, partnerPurposesOverridesQuery.data.purposeOverrides]);

  return {
    isLoading,
    processedVendors,
  };
};
