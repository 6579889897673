import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DidomiIconButton, DidomiTooltip } from '@didomi/ui-atoms-react';
import { useActiveOrganization, useHasAccessPolicies } from '@didomi/utility-react';
import { ACCESS_POLICIES_CONFIG } from '@access';

const EDIT_ACTION_PROPS_PER_VIEW_MODE = {
  edit: {
    text: 'Edit purpose',
    icon: 'edit',
    basePath: '/purpose',
  },
  'edit-global': {
    text: 'Edit purpose',
    icon: 'edit',
    basePath: '/global-purpose',
  },
  'view-only': {
    text: 'Preview purpose',
    icon: 'preview',
    basePath: '/preview-purpose',
  },
};

interface PurposeListItemActionButtonsProps {
  purpose: { id: string; organization_id: string };
  onDeleteClicked: () => void;
}

export const PurposeListItemActionButtons = ({ purpose, onDeleteClicked }: PurposeListItemActionButtonsProps) => {
  const { organizationId } = useActiveOrganization();
  const { hasAccess: canEdit } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.EDITOR);
  const navigate = useNavigate();

  const viewMode = useMemo(() => {
    if (!canEdit) {
      return 'view-only';
    } else if (purpose.organization_id === organizationId) {
      return 'edit';
    } else {
      return 'edit-global';
    }
  }, [purpose, canEdit, organizationId]);

  const editButtonProps = EDIT_ACTION_PROPS_PER_VIEW_MODE[viewMode];

  return (
    <div className="flex gap-xxs">
      <DidomiTooltip content={editButtonProps.text} placement="top">
        <DidomiIconButton
          data-tracking="edit-crud-list-action-button"
          title={editButtonProps.text}
          variant="rounded"
          icon={editButtonProps.icon}
          onClick={() => navigate(`${editButtonProps.basePath}/${purpose.id}`)}
        />
      </DidomiTooltip>
      {viewMode === 'edit' && (
        <DidomiTooltip content="Delete purpose" placement="top">
          <DidomiIconButton data-tracking="delete-crud-list-action-button" title="Delete purpose" variant="rounded" icon="delete" onClick={onDeleteClicked} />
        </DidomiTooltip>
      )}
    </div>
  );
};
