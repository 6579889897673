import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  DidomiFiltersBar,
  DidomiPaginator,
  DidomiSkeleton,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableRow,
  DidomiTableTh,
  DidomiTableTd,
  DidomiEmptyState,
} from '@didomi/ui-atoms-react';
import { useActiveOrganization } from '@didomi/utility-react';

import { useSessionStorage } from 'usehooks-ts';
import { usePaginatedFilteredItems, useTableState, useCollectedVendorsFromCr, useVendors } from '@hooks';
import { SortConfig, CollectedVendors, DraftVendor, VendorType } from '@types';
import { getUnknownAndBuiltInVendors } from '@utils';
const SEARCH_FIELDS = ['url_host', 'url'];
type sortableVendorDomainsFields = 'name';
const TRANSLATABLE_SEARCH_FIELDS = [];

interface UnmatchedVendorDomainsProps {
  selectedVendorHosts?: any[];
  setSelectedVendorHosts?: Function;
  matchingVendorHost: string | null;
}
const vendorDomainsTableHeader = [
  { name: 'Vendor domain', sortId: 'url_host' },
  { name: 'Matched On', sortId: 'functional_namespace' },
];

const typeOfVendor = (vendor: CollectedVendors) => {
  return vendor.functional_namespace === VendorType.BUILT_IN ? 'Model' : '';
};

const UnmatchedVendorDomains = ({ selectedVendorHosts, setSelectedVendorHosts }: UnmatchedVendorDomainsProps) => {
  const { organizationId } = useActiveOrganization();

  const [storedDraftVendorFormData] = useSessionStorage<DraftVendor>('draft-vendor-' + organizationId, null);

  // Select all custom vendors for organization to cross check against hosts
  const { isLoading: isLoadingCustomvendors, data: { data: customVendors } = {} } = useVendors({ fetchGlobalVendors: false });

  // Select the unmatched built_in vendors only
  const { data: vendors, isLoading: isLoadingCollectedVendors } = useCollectedVendorsFromCr({
    ids: [],
    unmatchedOnly: true,
    namespaces: [VendorType.BUILT_IN],
  });

  const customVendorHosts = (customVendors || []).map(customVendor => customVendor.hosts).flat();
  const hosts = Array.from(new Set(customVendorHosts));

  const vendorHosts = useMemo(() => {
    return getUnknownAndBuiltInVendors(vendors, hosts);
  }, [hosts, vendors]);
  const [sortConfig] = useState<SortConfig<sortableVendorDomainsFields>>({ field: 'name', dir: 'asc' });

  const {
    limit,
    page: currPage,
    search,
    changeLimit: setLimit,
    changeSearch: setSearch,
    changePage: setCurrPage,
    changeSorting,
  } = useTableState({
    defaultLimit: 10,
    defaultFilters: {},
  });
  const {
    data: displayVendorDomains,
    total: totalFilteredVendorsDomainCount,
    totalItems: totalFilteredVendorDomainsItems,
  } = usePaginatedFilteredItems<CollectedVendors, sortableVendorDomainsFields>(vendorHosts, {
    search,
    searchFields: SEARCH_FIELDS,
    translatableSearchFields: TRANSLATABLE_SEARCH_FIELDS,
    limit: limit,
    page: currPage,
    sortConfig,
  });
  const handleSearchChange = (e: CustomEvent<string>) => setSearch(e.detail);
  const [selectedVendorDomainsRef, setSelectedVendorDomainsRef] = useState([]);

  useEffect(() => {
    if (selectedVendorHosts) setSelectedVendorDomainsRef(selectedVendorHosts);
  }, [selectedVendorHosts]);

  useEffect(() => {
    if (storedDraftVendorFormData?.vendor?.hosts?.length) {
      const selectedHosts = totalFilteredVendorDomainsItems.filter(elm => storedDraftVendorFormData.vendor.hosts.includes(elm.url_host));
      setSelectedVendorDomainsRef(selectedHosts);
    }
  }, [totalFilteredVendorDomainsItems, storedDraftVendorFormData]);

  const handleSelection = useCallback(
    evt => {
      /* istanbul ignore if - Header checbox does not appear during jest test */
      if (evt.type === 'toggleAllRowsSelectedChange') {
        if (evt.detail.selectedItems.length) {
          setSelectedVendorDomainsRef(totalFilteredVendorDomainsItems);
          setSelectedVendorHosts(totalFilteredVendorDomainsItems);
        } else {
          setSelectedVendorDomainsRef([]);
          setSelectedVendorHosts([]);
        }
      } else {
        setSelectedVendorDomainsRef(evt.detail.newSelectedItems);
        setSelectedVendorHosts(evt.detail.newSelectedItems);
      }
    },
    [totalFilteredVendorDomainsItems, setSelectedVendorHosts],
  );

  return (
    <div className="pb-unsafe-bottom-space w-full">
      <DidomiSkeleton isLoading={isLoadingCollectedVendors || isLoadingCustomvendors} variant="layout">
        <>
          <section className="flex justify-between gap-8 mb-xs w-full" data-skeleton>
            <div className="flex gap-2 text-body-small self-center" style={{ alignSelf: 'center!important' }}>
              <div className="text-primary-blue-6 font-semibold">{`${displayVendorDomains.length} / ${vendorHosts.length} vendor domains`}</div>
              <div className="text-secondary-cobalt-blue-3">{selectedVendorDomainsRef.length ? selectedVendorDomainsRef.length + ' selected' : ''}</div>
            </div>

            <DidomiFiltersBar
              data-skeleton
              data-testid="filter-bar-vendor-domains"
              id="didomi-filters-without-default-value-vendor-domains"
              defaultValue=""
              filters={{}}
              showSearch={true}
              onSearchTextChange={handleSearchChange}
              placeholderTextFilter={'Search by name'}
              onClearAllFilters={setSearch}
              collapsibleFilters={false}
            ></DidomiFiltersBar>
          </section>
          <DidomiTable
            selectable
            selectedItems={selectedVendorDomainsRef}
            onRowSelectionChange={handleSelection}
            onToggleAllRowsSelectedChange={handleSelection}
            sortBy={sortConfig.field}
            sortDirection={sortConfig.dir}
            onSortChange={changeSorting}
            sortable={true}
            minWidth={300}
            data-skeleton
            className="w-[calc(100%-2px)] mx-auto mb-s"
          >
            <DidomiTableHeading>
              <DidomiTableHeaderRow>
                {vendorDomainsTableHeader.map(obj => (
                  <DidomiTableTh key={obj.name} sortId={obj.sortId} className="text-uppercase">
                    {obj.name}
                  </DidomiTableTh>
                ))}
              </DidomiTableHeaderRow>
            </DidomiTableHeading>
            <DidomiTableBody>
              {vendorHosts.length ? (
                displayVendorDomains.map((vendorDomain, i) => (
                  <DidomiTableRow key={vendorDomain.key} selectionValue={vendorDomain} data-testid={i}>
                    <DidomiTableTd>{vendorDomain.url_host}</DidomiTableTd>
                    <DidomiTableTd className="text-primary-pink-5">{typeOfVendor(vendorDomain)}</DidomiTableTd>
                  </DidomiTableRow>
                ))
              ) : (
                <DidomiEmptyState illustration-name="traces-no-match-found" className="border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1 mb-s">
                  <div slot="title">Unfortunately we don’t have any domains to suggest.</div>
                </DidomiEmptyState>
              )}
            </DidomiTableBody>
          </DidomiTable>

          <div className="flex justify-end">
            <DidomiPaginator
              data-skeleton
              data-testid="domains-paginator"
              page={currPage}
              itemCount={totalFilteredVendorsDomainCount}
              size={limit}
              onPageSizeChange={setLimit}
              onPageChange={setCurrPage}
            />
          </div>
        </>
      </DidomiSkeleton>
    </div>
  );
};

export { UnmatchedVendorDomains };
