import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { OperatorType } from '@didomi/cmp-generator';
import { getTranslatedValue } from '@didomi/helpers';
import {
  DidomiButton,
  DidomiSkeleton,
  DidomiHintbox,
  DidomiAutocompleteWithSearchField,
  DidomiSelectOptions,
  DidomiSelectOption,
  DidomiCollapsibleSection,
  DidomiSelectField,
  DidomiCheckboxField,
  DidomiNumberInputField,
  DidomiAutocompleteWithSearch,
  DidomiSelectionBlock,
  DidomiSwitchField,
} from '@didomi/ui-atoms-react';
import { useActiveOrganization, useReleaseFlag } from '@didomi/utility-react';
import { Form, Formik } from 'formik';
import { useSessionStorage } from 'usehooks-ts';

import { IABTCFVendorOverrideField, SpiSelectionWidget, VendorContactField, VendorStoragesField, VendorDomainFromInput, SanitizedTextInputField } from '@components';
import { useAllPurposes, useCountries, useProtections, usePartnerCategories, useServiceProvider, useRegulations, useGlobalPurposes } from '@hooks';
import { InvalidLiPurposesModal } from '@modals';
import { VendorRequestBody, DraftVendor, Purpose, VendorViewMode } from '@types';
import {
  COOKIE_DURATIONS,
  DEPRECATED_PURPOSES_IDS,
  getValidSpiPurposes,
  hasAtLeastOnePurpose,
  handleFormDataFromStorage,
  TCF_2p2_PURPOSES_BASED_ON_CONSENT,
  getPurposesIdsValueWithRequiredIds,
} from '@utils';
import { getVendorFormSchema } from './VendorFormSchema';

type VendorFormProps = {
  initialValues: any;
  onSubmit?: (newVendor: VendorRequestBody, cookies: any[]) => void;
  onCancel?: () => void;
  isLoading?: boolean;
  requiredDefaultPurposes?: string[];
  requiredLiPurposes?: string[];
  viewMode?: VendorViewMode;
  atLeastOnePurposeRequired?: boolean;
};

/**
 * Add/Edit Vendor Form
 */
export const VendorForm = ({
  initialValues,
  onCancel,
  onSubmit,
  isLoading = false,
  requiredDefaultPurposes = [],
  requiredLiPurposes = [],
  viewMode = 'edit',
  atLeastOnePurposeRequired = false,
}: VendorFormProps): JSX.Element => {
  const { data: allPurposes, isLoading: isLoadingPurposesList } = useAllPurposes();
  const { data: globalPurposes, isLoading: isLoadingGlobalPurposes } = useGlobalPurposes();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [mutableInitialValues, setMutableInitialValues] = useState(initialValues);
  const [isInvalidLiPurposesModalOpen, setIsInvalidLiPurposesModalOpen] = useState(false);

  const { data: visibleRegulations, isLoading: isLoadingRegulations } = useRegulations();
  const { organizationId, organization } = useActiveOrganization();

  /*
  -----------------------------------------------------------------------------------
  |  Using session Storage to Store Formulary Data                                    |
  |                                                                                 |
  |  The reason we're using session storage here is due to domain association that    |
  |  happens on a dedicated page. This helps to avoid losing current form data      |
  |  when re-rendering the form.                                                    |
  |                                                                                 |
  |  This session storage is created only when we navigate to associate domains. It   |
  |  gets cleared when an action is performed on the current vendor or when         |
  |  leaving the page.                                                              |
  |                                                                                 |
  -----------------------------------------------------------------------------------
  */
  const [storedDraftVendorFormData, setStoredDraftVendorFormData] = useSessionStorage<DraftVendor>('draft-vendor-' + organizationId, null);
  const visibleRegulationsIds = useMemo(() => {
    return visibleRegulations?.map(r => r.id) || [];
  }, [visibleRegulations]);

  const { data: countries, isLoading: loadingCountries } = useCountries();

  const { data: protections, isLoading: loadingProtections } = useProtections();

  const { data: partnersCategories, isLoading: loadingPartnersCategories } = usePartnerCategories();

  const { data: serviceProviders, isLoading: loadingServiceProviders } = useServiceProvider();

  const [isNewCmpEnabled] = useReleaseFlag('new-consent-notices');

  const isIABTCFVendorOverrideEnabled = !!organization.iab_tcf_cmp_id;
  const spiPurposes = useMemo(() => getValidSpiPurposes(allPurposes?.data, visibleRegulationsIds), [allPurposes, visibleRegulationsIds]);
  const [formValues, setformValues] = useState(null);
  const matchingVendorHost = searchParams.get('vendorKey');
  const { vendorId } = useParams();

  useEffect(() => {
    // Perform an exact key-value comparison between initialValues and mutableInitialValues
    // using JSON.stringify
    if (JSON.stringify(initialValues) !== JSON.stringify(mutableInitialValues)) {
      setMutableInitialValues(initialValues);
    }
  }, [initialValues, mutableInitialValues]);

  useEffect(() => {
    handleFormDataFromStorage({ storedDraftVendorFormData, setMutableInitialValues, setStoredDraftVendorFormData, matchingVendorHost, vendorId });
  }, [storedDraftVendorFormData, mutableInitialValues, setStoredDraftVendorFormData, matchingVendorHost, vendorId]);

  const getMappedFormData = submitData => {
    const address = submitData?.contact_information?.reduce((result, value) => ({ ...result, [value.language]: value.address }), {}) || null;
    const contact = submitData?.contact_information?.reduce((result, value) => ({ ...result, [value.language]: value.contactEmail }), {}) || null;

    let namespaces = submitData?.namespaces?.custom ? submitData.namespaces : null;

    if (submitData?.iab_tcf_vendor_id) {
      namespaces = {
        ...namespaces,
        iab2: Number(submitData.iab_tcf_vendor_id),
      };
    }
    sessionStorage.removeItem('draft-vendor-' + organizationId);

    return [
      {
        address,
        contact,
        spi_purposes_id: submitData.spi_purposes_id,
        cookie_max_duration: submitData?.cookie_max_duration ? parseInt(submitData?.cookie_max_duration, 10) : null,
        cookie_max_duration_unit: submitData?.cookie_max_duration_unit,
        country_id: submitData?.country_id,
        default_purposes_id: submitData?.default_purposes_id ? [...new Set(submitData?.default_purposes_id)] : submitData?.default_purposes_id,
        legitimate_interest_purposes_id: submitData?.legitimate_interest_purposes_id
          ? [...new Set(submitData?.legitimate_interest_purposes_id)]
          : submitData?.legitimate_interest_purposes_id,
        links: submitData?.links,
        name: submitData?.name,
        namespaces,
        organization_id: organizationId,
        protection_id: submitData?.protection_id,
        category_id: submitData?.category_id,
        taxonomy_id: submitData?.category_id ? submitData?.taxonomy_id : null,
        uses_non_cookie_access: submitData?.uses_non_cookie_access,
        hosts: submitData.hosts,
        type: submitData.type,
      },
      submitData?.cookie_information?.map(cI => ({ ...cI, organization_id: organizationId })),
    ];
  };

  const handleSubmit = submitData => {
    const selectedLiPurposes = submitData.legitimate_interest_purposes_id || [];
    const areLiPurposesValid = selectedLiPurposes.every(pId => !TCF_2p2_PURPOSES_BASED_ON_CONSENT.includes(pId));

    if (areLiPurposesValid) {
      const [newVendor, cookies] = getMappedFormData(submitData);
      onSubmit(newVendor, cookies);
    } else {
      setIsInvalidLiPurposesModalOpen(true);
    }
  };

  const purposeToSelect = useMemo(() => {
    if (!allPurposes?.data || !globalPurposes) return [];
    const purposesIds = (mutableInitialValues && mutableInitialValues.purposes_id) || [];

    const orgPurposes = allPurposes.data.filter(p => p.organization_id === organizationId);
    const combinedPurposes = orgPurposes.concat(globalPurposes);

    // Filter out deprecated purposes that are not selected(e.g. if deprecated purpose is selected, it should be displayed)
    return combinedPurposes.filter(p => !DEPRECATED_PURPOSES_IDS.includes(p.id) || (DEPRECATED_PURPOSES_IDS.includes(p.id) && purposesIds.includes(p.id)));
  }, [allPurposes?.data, globalPurposes, mutableInitialValues, organizationId]);

  const visiblePurposesAsLI = useMemo(() => {
    const isPurposeVisibleAsLi = (p: Purpose) => !TCF_2p2_PURPOSES_BASED_ON_CONSENT.includes(p.id) || initialValues?.legitimate_interest_purposes_id?.includes(p.id);

    return purposeToSelect.filter(isPurposeVisibleAsLi);
  }, [purposeToSelect, initialValues]);

  const formSchema = useMemo(() => {
    return getVendorFormSchema({ atLeastOnePurposeRequired });
  }, [atLeastOnePurposeRequired]);

  const handleRedirect = () => {
    const templateId = searchParams.get('templateId');
    const fromPath = searchParams.get('fromPath');
    const reportDomain = searchParams.get('reportDomain');
    setStoredDraftVendorFormData({
      vendor: formValues,
      key: matchingVendorHost || vendorId || 'add-vendor',
    });
    if (matchingVendorHost && templateId) {
      navigate(`associate-domains?vendorKey=${matchingVendorHost}&templateId=${templateId}&fromPath=${fromPath}`);
    } else if (matchingVendorHost && reportDomain) {
      navigate(`associate-domains?vendorKey=${matchingVendorHost}&reportDomain=${reportDomain}&fromPath=${fromPath}`);
    } else {
      const actionOnVendor = window.location.pathname.includes('add-vendor') ? 'creation' : 'update';
      navigate(`associate-domains?${actionOnVendor}=true&vendorKey=${formValues.name}`);
    }
  };
  const selectedIABTCFVendorID = initialValues?.namespaces?.iab2 || null;

  const hasSelectedPurposes = useMemo(() => {
    return hasAtLeastOnePurpose(formValues);
  }, [formValues]);

  const haveSPIPurposesBeenConfigured = !!initialValues.spi_purposes_id?.length;
  const isFullEditAvailable = viewMode === 'edit';
  const isLoadingData = isLoading || isLoadingPurposesList || isLoadingGlobalPurposes || isLoadingRegulations;

  return (
    <>
      <Formik
        initialValues={{ ...mutableInitialValues, namespaces: mutableInitialValues?.namespaces || {}, iab_tcf_vendor_id: selectedIABTCFVendorID }}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        // Using ref does not update the current.values since the component is not re rendered
        // This solution allow to extract current values
        innerRef={formikActions => formikActions && setformValues(formikActions.values)}
        validateOnMount={true}
      >
        {({ values, setFieldValue, setFieldTouched, errors }) => {
          return (
            <DidomiSkeleton className="w-full" isLoading={isLoadingData} variant="layout" data-testid={`vendor-form-skeleton-loading-${isLoadingData}`}>
              <Form className="w-full max-w-[540px] flex flex-col mb-s">
                <SanitizedTextInputField name="name" label="Vendor name *" placeholder="Enter a name..." data-skeleton={isLoading} disabled={!isFullEditAvailable} />

                <DidomiCollapsibleSection label="Vendor type" className="mb-s" data-skeleton={isLoading}>
                  <DidomiHintbox variant="neutral" className="mt-xxs mb-xxs">
                    You can define a vendor as your own property for use in your notices. Once configured, the vendor will no longer appear in the <b>Partners</b> section of the
                    notice.
                  </DidomiHintbox>
                  <DidomiSelectionBlock className="w-full h-full flex flex-col" selected={values.is_spi} disabled={!isFullEditAvailable}>
                    <div className="flex items-center gap-xs text-neutral-gray-7">
                      <div className="text-body-small font-semibold">Declare the vendor as your own property</div>
                      <DidomiSwitchField
                        data-testid="vendor-type-switch"
                        name="type"
                        className="!w-auto ml-auto"
                        valueSelected={OperatorType.FIRST_PARTY}
                        valueNotSelected={OperatorType.THIRD_PARTY}
                        hideErrorMessage
                        disabled={!isFullEditAvailable}
                      ></DidomiSwitchField>
                    </div>
                  </DidomiSelectionBlock>
                </DidomiCollapsibleSection>

                <SanitizedTextInputField
                  name="links.privacypolicy"
                  label="Privacy policy"
                  placeholder="Vendor privacy policy URL"
                  data-skeleton={isLoading}
                  disabled={!isFullEditAvailable}
                />

                <DidomiHintbox titleText="Define the purposes for which your vendor requires consent" variant="neutral" className="mt-xxs mb-xxs">
                  When the vendor is included in a consent notice, users can give or deny their consent to data processing by this vendor.
                </DidomiHintbox>
                <DidomiAutocompleteWithSearch
                  value={values.default_purposes_id}
                  data-testid="select-purposes-on-consent"
                  multi
                  label="Purposes based on consent"
                  placeholder="Select vendor purposes"
                  searchField="description"
                  items={purposeToSelect}
                  searchFieldModifier={getTranslatedValue}
                  disabled={viewMode === 'view-only'}
                  disableOptionGetter={purpose => requiredDefaultPurposes.includes(purpose.id)}
                  error={errors.default_purposes_id as string}
                  onValueChange={e => {
                    setFieldValue('default_purposes_id', getPurposesIdsValueWithRequiredIds(e.detail as string[], requiredDefaultPurposes));
                    setFieldTouched('default_purposes_id', true);
                  }}
                />
                <DidomiHintbox titleText="Define the purposes for which your vendor requires legitimate interest" variant="neutral" className="mt-xxs mb-xxs">
                  When the vendor is added to a consent notice, the user will be able to object to the data processing for that vendor.
                </DidomiHintbox>

                <DidomiHintbox variant="warning" iconName="warning" className="my-xxs">
                  Per IAB TCF v2.2 guidelines, Purposes 1,3,4,5 and 6 can only be based on <b>Consent</b>. These purposes cannot be included in Legitimate Interest.
                </DidomiHintbox>

                <DidomiAutocompleteWithSearch
                  value={values.legitimate_interest_purposes_id}
                  data-testid="select-purposes-on-li"
                  multi
                  label="Purposes based on legitimate interest"
                  placeholder="Select vendor purposes"
                  searchField="description"
                  items={visiblePurposesAsLI}
                  searchFieldModifier={getTranslatedValue}
                  disabled={viewMode === 'view-only'}
                  disableOptionGetter={purpose => requiredLiPurposes.includes(purpose.id)}
                  error={errors.legitimate_interest_purposes_id as string}
                  onValueChange={e => {
                    setFieldValue('legitimate_interest_purposes_id', getPurposesIdsValueWithRequiredIds(e.detail as string[], requiredLiPurposes));
                    setFieldTouched('legitimate_interest_purposes_id', true);
                  }}
                />
                <>
                  <DidomiHintbox titleText="Confirm vendor domains" variant="neutral" className="mt-xxs mb-xxs">
                    It&apos;s important to add all domains from which this vendor could drop trackers or call other vendors. If one of these domains is detected in a Didomi
                    compliance report, the vendor will not be marked as unmatched, increasing report accuracy. Listing the domains here is also useful if you intend to leverage the
                    CMP Vendors sync feature. To ensure you include all vendor domains, please keep an eye on the &quot;Compliance issues&quot; section of your compliance reports.
                  </DidomiHintbox>

                  {isFullEditAvailable && (
                    <VendorDomainFromInput
                      onSelectionChange={domains => {
                        setFieldValue('hosts', domains);
                      }}
                      hosts={values.hosts}
                      onRedirect={handleRedirect}
                    />
                  )}
                </>
                <DidomiCollapsibleSection label="Storage" className="mt-xs mb-m">
                  <DidomiHintbox variant="neutral" className="mt-xxs mb-xs">
                    You have to inform the user of the vendor&apos;s maximum cookie duration and if it also uses other storages than cookies to store and access information on
                    user&apos;s device.
                  </DidomiHintbox>
                  <div className="w-full flex items-end">
                    <DidomiNumberInputField
                      className="mr-xs"
                      min="0"
                      name="cookie_max_duration"
                      label="Cookie maximum duration"
                      placeholder="Enter value"
                      disabled={!isFullEditAvailable}
                    />
                    <DidomiSelectField className="max-w-[316px]" name="cookie_max_duration_unit" placeholder="Select cookie duration" disabled={!isFullEditAvailable}>
                      <DidomiSelectOptions>
                        {COOKIE_DURATIONS.map(cD => (
                          <DidomiSelectOption key={'cookie-duration-' + cD.value} value={cD.value} label={cD.name} />
                        ))}
                      </DidomiSelectOptions>
                    </DidomiSelectField>
                  </div>
                  <div className="flex justify-center">
                    <DidomiCheckboxField name="uses_non_cookie_access" label="The vendor uses additional storage methods" disabled={!isFullEditAvailable} />
                  </div>
                  {isFullEditAvailable && (
                    <>
                      <hr className="my-xs" />
                      <div className="flex flex-col items-center">
                        <div className="flex flex-col items-center text-primary-blue text-tooltip">
                          <span className="font-semibold">By adding a new storage,</span>
                          <span>you can add some information about each storage used by the vendor.</span>
                        </div>
                        <VendorStoragesField name="cookie_information" className="mt-s w-full" />
                      </div>
                    </>
                  )}
                </DidomiCollapsibleSection>
                <DidomiCollapsibleSection label="Contact" className="mb-m">
                  <DidomiHintbox variant="neutral" className="mt-xxs mb-xs">
                    You can add the address of the company&apos;s headquarters as well as an email address or a contact&apos;s phone number (preferably the DPO&apos;s or a privacy
                    address).
                  </DidomiHintbox>
                  {isFullEditAvailable && <VendorContactField name="contact_information" />}
                </DidomiCollapsibleSection>
                <DidomiCollapsibleSection label="Advanced" className="mb-m" data-skeleton={isLoading}>
                  <DidomiHintbox variant="neutral" className="mt-xxs mb-xs">
                    You can specify an SDK ID that maps to this vendor. Only do it if you are importing existing consent notices from JSON configurations and need to force set the
                    SDK ID of this vendor to a custom vendor ID used in your JSON configuration.
                  </DidomiHintbox>
                  <SanitizedTextInputField name="namespaces.custom" className="mt-xxs" label="Custom SDK ID" placeholder="Enter a custom SDK ID…" disabled={!isFullEditAvailable} />
                  <DidomiAutocompleteWithSearchField
                    name="protection_id"
                    label="Protection"
                    placeholder="Select a protection"
                    data-skeleton={loadingProtections}
                    items={protections?.data}
                    disabled={!isFullEditAvailable}
                  />
                  <DidomiAutocompleteWithSearchField
                    name="country_id"
                    label="Country"
                    placeholder="Select a country"
                    data-skeleton={loadingCountries}
                    items={countries?.data}
                    disabled={!isFullEditAvailable}
                  />
                  <DidomiAutocompleteWithSearchField
                    name="category_id"
                    label="Provider"
                    placeholder="Service provider"
                    data-skeleton={loadingPartnersCategories}
                    items={partnersCategories?.data}
                    disabled={!isFullEditAvailable}
                  />
                  <div className={values['category_id'] !== 'service-provider' ? '!hidden' : '!inline'} data-skeleton={loadingServiceProviders}>
                    <DidomiSelectField name="taxonomy_id" placeholder="Select a category..." label="Service provider category" disabled={!isFullEditAvailable}>
                      <DidomiSelectOptions>
                        {serviceProviders?.data?.map(sP => (
                          <DidomiSelectOption key={'service-provider-' + sP.id} value={sP.id} label={getTranslatedValue(sP.name)} disabled={!isFullEditAvailable} />
                        ))}
                      </DidomiSelectOptions>
                    </DidomiSelectField>
                  </div>
                </DidomiCollapsibleSection>

                {isNewCmpEnabled && haveSPIPurposesBeenConfigured && (
                  <DidomiCollapsibleSection label="Sensitive Personal Information (SPI)" aria-label="Toggle SPI for CPRA" className="mb-m" data-skeleton={isLoading}>
                    <DidomiHintbox iconName="warning" className="mb-xs" variant="warning">
                      Sensitive Personal Information (SPI) can be added in the Purpose section and managed in the notice to enable all purpose-related features.
                    </DidomiHintbox>
                    <DidomiHintbox variant="neutral" className="mt-1 mb-xs">
                      Sensitive Personal Information is defined as Personal Information that is not publicly available and reveals following information on the list below.
                    </DidomiHintbox>

                    {isFullEditAvailable && (
                      <SpiSelectionWidget
                        selectedSpiIds={values.spi_purposes_id}
                        availableSpiPurposes={spiPurposes}
                        onSelectionChange={spiIds => setFieldValue('spi_purposes_id', spiIds)}
                      />
                    )}
                  </DidomiCollapsibleSection>
                )}

                <DidomiCollapsibleSection label="Other links" className="mb-m" data-skeleton={isLoading}>
                  <SanitizedTextInputField name="links.website" label="Website" placeholder="Enter a website link" disabled={!isFullEditAvailable} />
                  <SanitizedTextInputField name="links.optout" label="Opt-out" placeholder="Enter an Opt-out link" disabled={!isFullEditAvailable} />
                  <SanitizedTextInputField name="links.terms" label="Terms & conditions" placeholder="Enter a Terms & conditions link" disabled={!isFullEditAvailable} />
                  <SanitizedTextInputField name="links.dpa" label="Data Processing Agreement" placeholder="Enter a DPA link" disabled={!isFullEditAvailable} />
                  <SanitizedTextInputField name="links.protection" label="Applicable protection" placeholder="Enter a Protection link" disabled={!isFullEditAvailable} />
                  <SanitizedTextInputField name="links.subprocessors" label="List of subcontractors" placeholder="Enter a subprocessors link" disabled={!isFullEditAvailable} />
                </DidomiCollapsibleSection>

                {isIABTCFVendorOverrideEnabled && <IABTCFVendorOverrideField name="iab_tcf_vendor_id" />}

                {!hasSelectedPurposes && (
                  <DidomiHintbox icon-name="warning" variant="warning" className="mb-m">
                    Define the purposes for this vendor or it will not appear in your notice.
                  </DidomiHintbox>
                )}
                {viewMode !== 'view-only' && (
                  <>
                    <div className="font-sans italic text-[12px] leading-[18px] mb-s text-primary-blue-6">* fields are required</div>
                    <div className="w-full flex justify-start">
                      <DidomiButton
                        type="button"
                        variant="secondary"
                        className="mr-xs"
                        disabled={isLoading}
                        onClick={() => {
                          sessionStorage.removeItem('draft-vendor-' + organizationId);
                          onCancel();
                        }}
                        data-skeleton
                      >
                        Cancel
                      </DidomiButton>
                      <DidomiButton data-tracking="vendor-save-button" type="submit" data-skeleton={isLoading}>
                        Save
                      </DidomiButton>
                    </div>
                  </>
                )}
              </Form>
            </DidomiSkeleton>
          );
        }}
      </Formik>
      <InvalidLiPurposesModal isOpen={isInvalidLiPurposesModalOpen} onClose={() => setIsInvalidLiPurposesModalOpen(false)} />
    </>
  );
};
