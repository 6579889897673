import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { Purpose } from '@types';
import { useFetchAllPaginatedResults } from '../../useFetchAllPaginatedResults.hook';

/**
 * Fetches the full (max 5000) list of purposes corresponding to the loaded organization
 * */
export const usePurposes = () => {
  const { organizationId } = useActiveOrganization();
  const { fetchAllPaginatedResults } = useFetchAllPaginatedResults();

  const fetchPurposes = async () => {
    return fetchAllPaginatedResults<Purpose>(`metadata/purposes?$translations=true&organization_id=${organizationId}`);
  };

  return useQuery<Purpose[], AxiosError>(['purposes', organizationId], fetchPurposes);
};
