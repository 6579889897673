import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OperatorType } from '@didomi/cmp-generator';
import { useSnackbar, useSPARouter } from '@didomi/utility-react';
import { useSessionStorage } from 'usehooks-ts';
import { VendorForm } from '@components';
import { useBackNavigation, useCreateVendor, useCreateVendorStorage, useSyncCreatedVendorWithComplianceReport } from '@hooks';
import { LoadingModal } from '@modals';
import { VendorRequestBody } from '@types';
import { extractIdsFromParams, handleRedirectionAfterMatching } from '@utils';

const initialVendorValues = {
  name: '',
  type: OperatorType.THIRD_PARTY,
  default_purposes_id: null,
  legitimate_interest_purposes_id: null,
  contact_information: null,
  country_id: null,
  category_id: 'service-provider',
  protection_id: null,
  taxonomy_id: null,
  cookie_max_duration: null,
  cookie_max_duration_unit: 'months',
  uses_non_cookie_access: null,
  cookie_information: null,
  hosts: [],
  links: {
    privacypolicy: '',
    website: '',
    optout: '',
    terms: '',
    dpa: '',
    protection: '',
    subprocessors: '',
  },
  namespaces: {
    custom: null,
  },
};

/**
 * Add Vendor Page
 */
export const AddVendor = (): JSX.Element => {
  const [initialValues, setInitialValues] = useState(initialVendorValues);
  const { displaySnackbar } = useSnackbar();
  const navigateBack = useBackNavigation();
  const { navigateTo } = useSPARouter();
  const [searchParams] = useSearchParams();

  const { mutateAsync: createVendor, isLoading: isCreatingVendor } = useCreateVendor();
  const { mutateAsync: syncCreateVendor, isLoading: isSynchronysingVendor } = useSyncCreatedVendorWithComplianceReport();

  const { mutateAsync: createVendorStorage, isLoading: isCreatingVendorStorage } = useCreateVendorStorage();

  const vendorKey = searchParams.get('vendorKey');
  const templateId = searchParams.get('templateId');
  const reportDomainId = searchParams.get('reportDomain');
  const [reportId, domainId] = extractIdsFromParams(reportDomainId);
  const [storedUnknownVendorDomains] = useSessionStorage('unknown-vendors-domains-' + reportId, null);

  // Watcher based on url params
  // it pre fill the vendor form data based on unknown vendor hosts
  // the pre fill can be one host or a list of host depending on the flow
  // from the CMP Vendors sync (SVL) app or the ACM (CR) app
  useEffect(() => {
    if (vendorKey) {
      setInitialValues({
        ...initialVendorValues,
        name: vendorKey,
        hosts:
          reportDomainId && reportId && domainId && storedUnknownVendorDomains?.length > 0
            ? [...storedUnknownVendorDomains.map(unknownVendor => unknownVendor?.first_request?.url_sld || unknownVendor?.first_request?.url_host)]
            : [vendorKey],
      });
    } else {
      setInitialValues({
        ...initialVendorValues,
      });
    }
  }, [vendorKey, storedUnknownVendorDomains, reportDomainId, reportId, domainId]);

  const handleVendorCreation = async (newVendorData: VendorRequestBody, vendorStorages: any[]) => {
    try {
      const vendorCreated = await createVendor(newVendorData);
      await syncCreateVendor(vendorCreated.data);

      if (vendorStorages?.length) {
        for (const vendorStorage of vendorStorages) {
          await createVendorStorage({ ...vendorStorage, partner_id: vendorCreated.data.id });
        }
      }

      displaySnackbar(`Vendor "${vendorCreated?.data?.name}" has been created successfully!`, { icon: 'check' });
      return handleRedirectionAfterMatching({ vendorKey, reportDomainId, domainId, reportId, templateId, navigateTo, navigateBack });
    } catch (e) {
      displaySnackbar('There was an error adding the vendor', { icon: 'danger-light', variant: 'error' });
    }
  };

  return (
    <main className="w-full pb-10">
      <VendorForm
        initialValues={initialValues}
        onSubmit={handleVendorCreation}
        onCancel={() => (vendorKey ? navigateTo(`smart-vendor-list/matching-vendor?${templateId}&vendorKey=${vendorKey}`) : navigateBack('/vendors'))}
      />
      <LoadingModal data-cy="add-vendor-saving" isOpen={isCreatingVendor || isCreatingVendorStorage || isSynchronysingVendor} title="We are creating your vendor..." />
    </main>
  );
};
