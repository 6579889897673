import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, Purpose } from '@types';

/**
 * Deletes a purpose, makes sure to update the list of purposes to remove the purpose from there as well
 * */
export const useDeletePurpose = (options?: UseMutationOptions<AxiosResponse<Purpose>, AxiosError, string>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    async (purposeId: string) => {
      return axiosWithInterceptors.delete(`${CONFIG.environmentConfig.apiBaseUrl}metadata/purposes/${purposeId}?$translations=true`);
    },
    {
      ...options,
      onSuccess: (newPurpose: AxiosResponse<Purpose>, variables, context) => {
        const purposesGet: Purpose[] = queryClient.getQueryData(['purposes', organizationId]);
        /* istanbul ignore if */
        if (purposesGet) {
          queryClient.setQueryData(['purposes', organizationId], () => purposesGet.filter(p => p.id !== newPurpose.data.id));
        }

        const allPurposesGet: PaginatedResponse<Purpose> = queryClient.getQueryData(['purposes']);
        /* istanbul ignore if */
        if (allPurposesGet) {
          queryClient.setQueryData(['purposes'], {
            ...allPurposesGet,
            data: allPurposesGet.data.filter(p => p.id !== newPurpose.data.id),
            total: allPurposesGet.total - 1,
          });
        }

        if (options?.onSuccess) {
          options.onSuccess(newPurpose, variables, context);
        }
      },
    },
  );
};
