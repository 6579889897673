import { useEffect, useMemo, useState } from 'react';

/**
 * Custom hook to manage a selection state with an "All" option(batch selection functionality)
 * It allows:
 * - to have 'all' option in the values passed to the select atom('selectValue') but omit it from value that would be saved('fieldValue')
 * - to handle batch selection via the `handleValueChange` function
 */
export const useSelectWithAllState = (initialValue: string[] | null, optionsValues: string[] = []) => {
  const [selectValue, setSelectValue] = useState<string[]>(initialValue);

  // If all options are selected - the field value is null, otherwise exclude "All" from the selection(as it is only UI representation)
  const fieldValue = useMemo(() => {
    const selectedOptions = selectValue?.filter(value => value !== 'all') || [];
    return selectedOptions.length === optionsValues.length ? null : selectedOptions;
  }, [selectValue, optionsValues]);

  useEffect(() => {
    if (!optionsValues?.length) return;

    if (initialValue === null) {
      setSelectValue(optionsValues.concat('all'));
    } else {
      setSelectValue(initialValue || []);
    }
  }, [initialValue, optionsValues]);

  const handleValueChange = (emittedValue: string[]) => {
    // Select all options if "All" has been just selected(but wasn't before)
    const isAllJustSelected = emittedValue.includes('all') && !selectValue.includes('all');
    if (isAllJustSelected) {
      setSelectValue(optionsValues.concat('all'));
      return;
    }

    // Deselect all options if "All" has been just deselected(but was selected before)
    const isAllJustDeselected = !emittedValue.includes('all') && selectValue.includes('all');
    if (isAllJustDeselected) {
      setSelectValue([]);
      return;
    }

    // Select "All" if all options are selected manually
    const areAllOptionsSelected = emittedValue.length === optionsValues.length && !emittedValue.includes('all');
    if (areAllOptionsSelected) {
      setSelectValue(['all', ...emittedValue]);
      return;
    }

    // Otherwise, set emitted selection - without "All"
    setSelectValue(emittedValue.filter(value => value !== 'all'));
  };

  return { fieldValue, selectValue, onValueChange: handleValueChange };
};
