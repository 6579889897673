import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { Purpose } from '@types';
import { useFetchAllPaginatedResults } from '../../useFetchAllPaginatedResults.hook';

export const useGlobalPurposes = (options?: UseQueryOptions<Purpose[]>) => {
  const { fetchAllPaginatedResults } = useFetchAllPaginatedResults();

  const fetchPurposes = async () => {
    return fetchAllPaginatedResults<Purpose>('metadata/global-purposes?$translations=true');
  };

  return useQuery<Purpose[], AxiosError>(['global-purposes'], fetchPurposes, options);
};
