import { useMemo } from 'react';
import { Purpose, Vendor } from '@types';
import { useVendor } from './useVendor.hook';
import { useAllPurposes } from '../purposes/useAllPurposes.hook';

const isIab2Purpose = (purposeId: string, allPurposes: Purpose[]) => {
  const purpose = allPurposes.find(p => p.id === purposeId);
  return purpose?.namespaces?.iab2 > 0 || purpose?.namespaces?.iab2_special_feature > 0;
};

const isAtpVendor = (vendor: Vendor) => Number.isInteger(vendor?.namespaces?.google);
const isIab2Vendor = (vendor: Vendor) => vendor?.namespaces?.iab2 > 0;

// Get purposes that can not be unselected for a vendor during the purpose override process(e.g. IAB purposes)
export const useVendorOverridesRequiredPurposes = (vendorId: string) => {
  const { data: vendor, isLoading: loadingVendor } = useVendor(vendorId);
  const { data: purposes, isLoading: loadingPurposes } = useAllPurposes();

  // IAB purposes are required only for IAB vendors and ATP vendors
  const shouldRequiredPurposesBeCalculated = useMemo(() => {
    return isIab2Vendor(vendor) || isAtpVendor(vendor);
  }, [vendor]);

  const requiredDefaultPurposes = useMemo(() => {
    if (!shouldRequiredPurposesBeCalculated) {
      return [];
    }

    const vendorDefaultPurposes = vendor?.default_purposes_id || [];
    const allPurposes = purposes?.data || [];
    return vendorDefaultPurposes.filter(pId => isIab2Purpose(pId, allPurposes));
  }, [vendor, purposes, shouldRequiredPurposesBeCalculated]);

  const requiredLiPurposes = useMemo(() => {
    if (!shouldRequiredPurposesBeCalculated) {
      return [];
    }

    const vendorDefaultPurposes = vendor?.legitimate_interest_purposes_id || [];
    const allPurposes = purposes?.data || [];
    return vendorDefaultPurposes.filter(pId => isIab2Purpose(pId, allPurposes));
  }, [vendor, purposes, shouldRequiredPurposesBeCalculated]);

  return {
    requiredDefaultPurposes,
    requiredLiPurposes,
    loading: loadingVendor || loadingPurposes,
  };
};
