import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { ExistingVendorPurposeOverride, PurposeVariantPath } from '@types';
import { getPurposesOverridesDiffForVendor } from '@utils';
import { useAxiosWithLogOut } from '../../useAxiosWithInterceptors.hook';

const BASE_URL = `${CONFIG.environmentConfig.apiBaseUrl}metadata/`;

interface EditVendorPurposesOverridesContext {
  vendorId: string;
  purposeVariantPath: PurposeVariantPath;
  existingOverrides: ExistingVendorPurposeOverride[];
  originalPurposesIds: string[];
}

/**
 * Update vendor purposes overrides for default purposes or legitimate interest purposes
 * */
export const useEditVendorPurposesOverrides = (
  { vendorId, purposeVariantPath, existingOverrides, originalPurposesIds }: EditVendorPurposesOverridesContext,
  options?: UseMutationOptions<AxiosResponse<any>[], AxiosError, string[]>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const addOverrides = (vendorId: string, purposeIds: string[]) => {
    const payload = purposeIds.map(purposeId => ({ organization_id: organizationId, metadata_partner_id: vendorId, metadata_purpose_id: purposeId, regulation_id: null }));
    return axiosWithInterceptors.post(`${BASE_URL}${purposeVariantPath}`, payload);
  };
  const deleteOverride = (id: string) => axiosWithInterceptors.delete(`${BASE_URL}${purposeVariantPath}/${id}`);

  return useMutation(
    async (newPurposeIds: string[]) => {
      const { toAdd, toDelete } = getPurposesOverridesDiffForVendor(vendorId, existingOverrides, newPurposeIds, originalPurposesIds);
      const requests = [];

      if (toAdd.length) {
        requests.push(addOverrides(vendorId, toAdd));
      }

      if (toDelete.length) {
        toDelete.forEach(id => requests.push(deleteOverride(id)));
      }

      return Promise.all(requests);
    },
    {
      ...(options || {}),
      onSuccess: () => {
        queryClient.invalidateQueries(purposeVariantPath);
      },
    },
  );
};
