import { Vendor } from 'types/vendor';

/**
 * Check if the vendor has any purposes (either default or legitimate interest).
 *
 * @param vendor - The vendor to check.
 * @returns True if the vendor has at least one purpose, false otherwise.
 */
export const hasAnyPurpose = (vendor: Vendor): boolean => {
  return vendor.default_purposes_id?.length > 0 || vendor.legitimate_interest_purposes_id?.length > 0;
};
