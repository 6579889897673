import { NavLink, useNavigate } from 'react-router-dom';
import React, { useState, useMemo } from 'react';
import { getCounterText, getTranslatedValue } from '@didomi/helpers';
import { DidomiFiltersBarCustomEvent } from '@didomi/ui-atoms';
import {
  DidomiEmptyState,
  DidomiButton,
  DidomiIconButton,
  DidomiTable,
  DidomiTableHeading,
  DidomiTableHeaderRow,
  DidomiTableTh,
  DidomiTableBody,
  DidomiTableRow,
  DidomiTableTd,
  DidomiPaginator,
  DidomiSkeleton,
  DidomiHintbox,
  DidomiListShortener,
  DidomiErrorState,
  DidomiTooltip,
  DidomiIcon,
  DidomiFiltersBar,
} from '@didomi/ui-atoms-react';
import { useSnackbar, useHasAccessPolicies, useActiveOrganization } from '@didomi/utility-react';
import { useQueryClient } from 'react-query';
import { ACCESS_POLICIES_CONFIG } from '@access';
import { TextWithHighlighterAndTooltip, VendorCard } from '@components';
import { useVendors, usePaginationQueryParams, usePaginatedFilteredItems, useDeleteVendor, useSyncDeletedVendorWithComplianceReport } from '@hooks';
import { RemoveItemConfirmationModal, LoadingModal } from '@modals';
import { Vendor, SortConfig, VendorFilterType, VendorsSortableFiled } from '@types';
import { useFilteredVendors, hasAnyPurpose } from '@utils';

const SEARCH_FIELDS = ['id', 'sdk_id', 'default_purposes_name', 'legitimate_interest_purposes_name'];
const TRANSLATABLE_SEARCH_FIELDS = ['name'];

/**
 * Vendors Default Page
 */
export const Vendors = (): JSX.Element => {
  const [deletingVendor, setDeletingVendor] = useState<Partial<Vendor>>(null);
  const [tableView, setTableView] = useState<boolean>(false);

  const navigate = useNavigate();
  const { displaySnackbar } = useSnackbar();
  const [sortConfig, setSortConfig] = useState<SortConfig<VendorsSortableFiled>>({ field: 'name', dir: 'asc' });
  const { isLoading, isRefetching, data: { data: vendors, total: totalVendors } = {}, error: errorLoadingVendors } = useVendors();
  const { organizationId } = useActiveOrganization();

  const activeVendors = useMemo(() => {
    return vendors?.filter(vendor => {
      return vendor.deprecated !== true && hasAnyPurpose(vendor);
    });
  }, [vendors]);

  const [vendorsFilter, setVendorsFilter] = useState<VendorFilterType>(VendorFilterType.CUSTOM);

  const filteredVendors = useFilteredVendors(activeVendors, vendorsFilter, organizationId);

  const vendorsFilters = useMemo(
    () => ({
      filterByVendor: {
        defaultValue: VendorFilterType.ALL,
        value: vendorsFilter,
        options: [
          { value: VendorFilterType.ALL, label: 'All vendors' },
          { value: VendorFilterType.CUSTOM, label: 'Custom vendors' },
          { value: VendorFilterType.IAB, label: 'IAB vendors' },
          { value: VendorFilterType.ATP, label: 'Google ATP vendors' },
        ],
      },
    }),
    [vendorsFilter],
  );

  const handleFilterChange = (e: DidomiFiltersBarCustomEvent<{ key: string; newValue: any }>) => {
    const filterValue = e.detail.newValue;
    setVendorsFilter(filterValue);
  };

  const handleClearFilters = () => {
    setSearch('');
    setVendorsFilter(VendorFilterType.ALL);
  };

  const { search, limit, page: currPage, setSearch, setLimit, setPage: setCurrPage } = usePaginationQueryParams({ maxItems: totalVendors });
  const { hasAccess: canEdit } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.EDITOR);

  const { data: displayVendors, total: paginationTotal } = usePaginatedFilteredItems<Vendor, VendorsSortableFiled>(filteredVendors, {
    search,
    searchFields: SEARCH_FIELDS,
    translatableSearchFields: TRANSLATABLE_SEARCH_FIELDS,
    limit,
    page: currPage,
    sortConfig,
  });

  const queryClient = useQueryClient();

  const { mutate: deleteVendor, isLoading: isDeletingVendor } = useDeleteVendor({
    onSuccess: deletedPurpose => {
      setDeletingVendor(null);
      displaySnackbar(`Vendor "${getTranslatedValue(deletedPurpose.data.name)}" has been deleted successfully!`, { icon: 'check' });

      // Invalidate the vendors query to trigger a refetch
      queryClient.invalidateQueries(['vendors']);
    },
    onError: () => {
      displaySnackbar('There was an error deleting the vendor', { icon: 'danger-light', variant: 'error' });
    },
  });
  const { mutateAsync: syncDeleteVendorWithCr, isLoading: isSynchronysingVendor } = useSyncDeletedVendorWithComplianceReport();

  const handleVendorDeletetion = deletingVendor => {
    deleteVendor(deletingVendor.id);
    syncDeleteVendorWithCr(deletingVendor.id);
  };

  const vendorsCount = activeVendors?.length || 0;
  const noDisplayVendors = !displayVendors?.length;
  const noVendors = !activeVendors?.length;
  const countText = getCounterText(vendorsCount, 'result', 'results');
  const loadingVendors = isLoading || isRefetching;

  const updateSorting = (e: CustomEvent) => {
    const newSortBy = e.detail.sortId;
    const newSortDir = newSortBy !== sortConfig.field ? 'desc' : e.detail.direction;
    setSortConfig({ dir: newSortDir, field: newSortBy ?? sortConfig.field });
  };

  const resultsText = `${paginationTotal < vendorsCount ? `${paginationTotal} of ` : ''}${countText}`;

  return (
    <>
      <div className="w-full h-full pt-6 flex flex-col">
        {canEdit && (
          <DidomiHintbox iconName="warning" variant="warning" className="mb-m">
            You can modify only your custom vendors. When creating a custom vendor please define their purposes, or it will not appear in your notice.
          </DidomiHintbox>
        )}

        {!noVendors && (
          <div className="flex justify-end flex-wrap-reverse gap-xs items-center mb-xs w-full">
            <DidomiFiltersBar
              data-testid="filter-bar"
              className="flex-1"
              leftText={resultsText}
              filters={vendorsFilters}
              placeholderTextFilter="Search a vendor"
              showSearch
              searchValue={search}
              collapsibleFilters={false}
              onSearchTextChange={setSearch}
              onClearAllFilters={handleClearFilters}
              onFilterValueChange={handleFilterChange}
            />

            <div>
              <button
                data-testid="table-view-button"
                onClick={() => setTableView(true)}
                className={`ml-1 w-[32px] h-[32px] !outline-none focus:ring ring-primary-blue-2 rounded ${tableView ? 'text-primary-blue-6' : 'text-neutral-gray-5'}`}
              >
                <DidomiIcon name="table" />
              </button>
              <button
                data-testid="cards-view-button"
                onClick={() => setTableView(false)}
                className={`ml-1 w-[32px] h-[32px] !outline-none focus:ring ring-primary-blue-2 rounded ${tableView ? 'text-neutral-gray-5' : 'text-primary-blue-6'}`}
              >
                <DidomiIcon name="card" />
              </button>
            </div>

            {canEdit && (
              <DidomiButton data-tracking="add-crud-list-button" iconRight="new-create" onClick={() => navigate('/add-vendor')}>
                Add vendor
              </DidomiButton>
            )}
          </div>
        )}

        {/* Render skeleton cards while loading */}
        {loadingVendors && (
          <div className="flex flex-col w-full">
            {Array.from({ length: 3 }).map((_, index) => (
              <DidomiSkeleton key={index} isLoading={loadingVendors} className="bg-neutral-gray-2 mb-4 w-full h-[210px]"></DidomiSkeleton>
            ))}
          </div>
        )}

        {!noVendors && !noDisplayVendors && (
          <>
            <DidomiSkeleton variant="layout" isLoading={loadingVendors}>
              {!tableView && (
                <div data-testid={loadingVendors ? 'loadingVendors-cards' : 'vendors-cards'} className="mb-4">
                  {displayVendors.map(vendor => (
                    <VendorCard
                      key={vendor.id}
                      data-testid={vendor.id}
                      data-cy={vendor.id}
                      vendor={vendor}
                      searchText={search}
                      onVendorDelete={v => setDeletingVendor(v)}
                      editable={canEdit && vendor.organization_id === organizationId}
                    />
                  ))}
                </div>
              )}

              {tableView && (
                <DidomiTable
                  data-testid={loadingVendors ? 'loading-vendors-table' : 'vendors-table'}
                  data-cy={loadingVendors ? 'loading-vendors-table' : 'vendors-table'}
                  fixedLayout
                  className="mb-4"
                  loading={loadingVendors}
                  sortable
                  sortBy={sortConfig.field}
                  sortDirection={sortConfig.dir}
                  onSortChange={updateSorting}
                >
                  <DidomiTableHeading>
                    <DidomiTableHeaderRow>
                      <DidomiTableTh sortId="name" data-testid="sortName">
                        NAME
                      </DidomiTableTh>
                      <DidomiTableTh sortId="id" data-testid="sortId">
                        API ID
                      </DidomiTableTh>
                      <DidomiTableTh sortId="sdk_id" data-testid="sortSdkId">
                        SDK ID
                      </DidomiTableTh>
                      <DidomiTableTh sortId="default_purposes_name" data-testid="sortPurposeName">
                        PURPOSES (CONSENT)
                      </DidomiTableTh>
                      <DidomiTableTh sortId="legitimate_interest_purposes_name" data-testid="sortPurposeLegitimateInterest">
                        PURPOSES (LEGITIMATE INTEREST)
                      </DidomiTableTh>
                      <DidomiTableTh>WEBSITES</DidomiTableTh>
                      {canEdit && <DidomiTableTh cellAlign="right" style={{ maxWidth: 152 }}></DidomiTableTh>}
                    </DidomiTableHeaderRow>
                  </DidomiTableHeading>
                  <DidomiTableBody>
                    {displayVendors.map(({ name, id, sdk_id, default_purposes_name, legitimate_interest_purposes_name, links_readable, organization_id }, i) => (
                      <DidomiTableRow key={id} selectionValue={id} data-testid={id} data-cy={`vendors-row-${i}`}>
                        <DidomiTableTd>
                          <div data-skeleton={loadingVendors} className="truncate w-full">
                            <NavLink to={`/vendor/${id}`} className="underline !outline-none inline-block focus-visible:(ring-[3px] ring-primary-blue-2)">
                              <TextWithHighlighterAndTooltip searchText={search} text={getTranslatedValue(name, '-')} />
                            </NavLink>
                          </div>
                        </DidomiTableTd>
                        <DidomiTableTd>
                          <div data-skeleton={loadingVendors} className="truncate w-full">
                            <TextWithHighlighterAndTooltip searchText={search} text={id || '-'} />
                          </div>
                        </DidomiTableTd>
                        <DidomiTableTd>
                          <div data-skeleton={loadingVendors} className="truncate w-full">
                            <TextWithHighlighterAndTooltip searchText={search} text={sdk_id || '-'} />
                          </div>
                        </DidomiTableTd>
                        <DidomiTableTd>
                          <div data-skeleton={loadingVendors} className="w-full">
                            {default_purposes_name?.length ? <DidomiListShortener items={default_purposes_name} truncate /> : '-'}
                          </div>
                        </DidomiTableTd>
                        <DidomiTableTd>
                          <div data-skeleton={loadingVendors} className="w-full">
                            <div className="w-full">
                              {legitimate_interest_purposes_name?.length ? <DidomiListShortener items={legitimate_interest_purposes_name} truncate /> : '-'}
                            </div>
                          </div>
                        </DidomiTableTd>
                        <DidomiTableTd>
                          <div data-skeleton={loadingVendors} className="w-full">
                            <div className="w-full">
                              {links_readable?.length ? (
                                <>
                                  <div hidden id={'website-tooltip-content' + id}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                      {links_readable.map(
                                        /* istanbul ignore next */ link =>
                                          link.value ? (
                                            <a
                                              style={{
                                                fontSize: '14px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                margin: '2px 0',
                                              }}
                                              key={id + link.label}
                                              href={link.value}
                                              rel="noreferrer noopener"
                                              target="_blank"
                                            >
                                              <DidomiIcon name="link" />
                                              <span style={{ marginLeft: '8px' }}>{link?.label}</span>
                                            </a>
                                          ) : null,
                                      )}
                                    </div>
                                  </div>
                                  <DidomiTooltip contentElementId={'website-tooltip-content' + id} variant="helper" interactive>
                                    <div>
                                      {links_readable?.length} Website{links_readable?.length > 1 ? 's' : ''}
                                    </div>
                                  </DidomiTooltip>
                                </>
                              ) : (
                                '-'
                              )}
                            </div>
                          </div>
                        </DidomiTableTd>
                        {canEdit && organization_id === organizationId && (
                          <DidomiTableTd cellAlign="right" style={{ maxWidth: 152 }}>
                            <div className="flex px-xxxs">
                              <DidomiTooltip content="Edit vendor" placement="top">
                                <DidomiIconButton
                                  data-tracking="edit-crud-list-action-button"
                                  type="button"
                                  title="Edit vendor"
                                  variant="rounded"
                                  icon="edit"
                                  className="mr-xxs"
                                  onClick={() => navigate(`/vendor/${id}`)}
                                />
                              </DidomiTooltip>
                              <DidomiTooltip content="Delete vendor" placement="top">
                                <DidomiIconButton
                                  data-tracking="delete-crud-list-action-button"
                                  type="button"
                                  title="Delete vendor"
                                  variant="rounded"
                                  icon="delete"
                                  onClick={() => setDeletingVendor({ id, name, sdk_id })}
                                />
                              </DidomiTooltip>
                            </div>
                          </DidomiTableTd>
                        )}

                        {canEdit && organization_id !== organizationId && (
                          <DidomiTableTd cellAlign="center" style={{ maxWidth: 152 }}>
                            <DidomiTooltip className="px-xxxs" content="View vendor" placement="top">
                              <DidomiIconButton
                                data-tracking="view-crud-list-action-button"
                                type="button"
                                title="View vendor"
                                variant="rounded"
                                icon="Preview"
                                onClick={() => navigate(`/preview-vendor/${id}`)}
                              />
                            </DidomiTooltip>
                          </DidomiTableTd>
                        )}
                      </DidomiTableRow>
                    ))}
                  </DidomiTableBody>
                </DidomiTable>
              )}
            </DidomiSkeleton>
            <DidomiPaginator
              data-testid="vendors-paginator"
              className="self-end"
              page={currPage}
              itemCount={paginationTotal}
              size={limit}
              onPageSizeChange={setLimit}
              onPageChange={setCurrPage}
              disabled={loadingVendors}
            />
          </>
        )}

        {!noVendors && noDisplayVendors && !isLoading && (
          <DidomiEmptyState illustration-name="list-cannot-be-loaded" className="border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1 mb-s">
            No results
          </DidomiEmptyState>
        )}
      </div>

      {noVendors && errorLoadingVendors && (
        <div className="w-full h-full mt-s">
          <DidomiErrorState illustration-name="list-cannot-be-loaded" className="h-full border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1">
            <div slot="title">There was an error loading the vendors list</div>
          </DidomiErrorState>
        </div>
      )}
      {noVendors && !isLoading && !errorLoadingVendors && (
        <div className="w-full h-full mt-s">
          <DidomiEmptyState
            illustration-name="no-widget-yet"
            className="h-full border-1 border-dashed border-neutral-gray-5 rounded-lg py-8"
            actionName="Add vendor"
            onActionClick={() => navigate(`/add-vendor`)}
          >
            <div slot="title">You have no vendors yet</div>
          </DidomiEmptyState>
        </div>
      )}
      <RemoveItemConfirmationModal
        itemName={getTranslatedValue(deletingVendor?.name)}
        isOpen={!!deletingVendor && !isDeletingVendor && !isSynchronysingVendor}
        onCancel={() => setDeletingVendor(null)}
        onProceed={() => handleVendorDeletetion(deletingVendor)}
      />
      <LoadingModal data-cy="delete-vendor-loading" isOpen={isDeletingVendor && isSynchronysingVendor} title="We are deleting your vendor..." />
    </>
  );
};
